import { Component, OnInit, Input, TemplateRef, Output, EventEmitter } from '@angular/core';
import { MetaPageService } from '@app/core/meta-page.service';
import { PageEvent } from '@angular/material/paginator';
import { MatPaginatorIntl } from '@angular/material/paginator';


@Component({
  selector: 'wo-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
    advanced_filters = false;
    isLoading = false;
    @Output() onPressSearch = new EventEmitter();
    @Output() onPressReset = new EventEmitter();
    @Output() onPressPage = new EventEmitter();
    @Input() search;
    @Input() rows;
    @Input() curr_page;
    @Input() total_page_items;

    min_limit: number = 20;
    pageSize = 0;

    pageSizeOptions: number[] = [20, 50, 100];

    constructor(
        public metaPage: MetaPageService,
        public pageEvent: PageEvent,
        public matPaginatorIntl: MatPaginatorIntl,
    ) { }

    ngOnInit(): void {
    }

    doSearch () {
        this.onPressSearch.emit({value: this.search.inpCommon});
    }

    toggleAdvancedFilter() {
        this.advanced_filters = !this.advanced_filters;
    }

    toPage(event) {
        this.onPressPage.emit(event);
    }

    getPaginatorData(event) {
        this.search.page = ++event['pageIndex'];
        this.search.limit  = event['pageSize'];
        this.onPressPage.emit({page: this.search.page, limit: this.search.limit});
        return event;
    }

    resetFilter(event) {
        for (const prop of Object.keys(this.search.condition)) {
          this.search.condition[prop] = '';
        }

        this.search.common = '';
        this.search.inpCommon = '';
        this.onPressReset.emit();
    }
}
