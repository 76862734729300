import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HttpService } from '@app/core/http.service';
import { AuthService } from '@app/auth/auth.service';
import { Router } from '@angular/router';
import { WoFlashService } from '@app/wo-module/wo-flash/wo-flash.service';
import { environment } from '../../../environments/environment';

@Component({
    templateUrl: 'forgot-password.component.html',
    styleUrls: ['forgot-password.component.css']
})

export class ForgotPasswordComponent {
    email = '';
    formErrors: any;
    isLoading = false;

    constructor (
        private title: Title,
        private http: HttpService,
        private auth: AuthService,
        private router: Router,
        protected woFlash: WoFlashService,
    ) {
        this.title.setTitle('Login');
    }

    send() {
        if (this.isLoading === true) {
            return false;
        }
        this.isLoading = true;
        this.formErrors = {};
        this.http.send(
            environment.API_BASE_URL + '/general/auth/forgot-password',
            {email: this.email},
            {}
        ).then((res) => {
            this.isLoading = false;
            if (!res['errors']) {
                this.email = '';
                setTimeout(() => {
                    this.woFlash.addMessage('Please check your email and click on the provided link to reset your password.');
                    this.woFlash.show();
                }, 200);
                // this.auth.setToken(res['access_token'], res['expires_in']);
                // this.auth.setRefreshToken(res['refresh_token']);
                // this.auth.setUser(res['user']);
                // this.router.navigate(['/admin']);
            } else {
                setTimeout(() => {
                    this.formErrors = res['errors'];
                }, 200);
            }
        }).catch(res => {
            this.formErrors = {};
            this.isLoading = false;
            alert('Oops! Something went wrong.');
        });
    }
}
