<div class="c-lob-picker__container" [enableGhostResize]="false" [ngStyle]="{'width': resizable ? (currentWidth+'px') : startWidth}" (mousedown)="resizing = true" (mouseout)="resizing = false" (resizing)="resize($event)" mwlResizable>
	<div class="c-lob-picker">
		<form class="c-lob-picker__search-form" action="javascript:void(0);" (submit)="onSearch()">
			<div class="c-lob-picker__search-body">
				<div class="c-lob-picker__search-field">
					<mat-form-field>
						<mat-label>Search</mat-label>
						<input name="common_search" matInput (enter)="onSearch()" [(ngModel)]="search">
					</mat-form-field>
				</div>

				<div class="c-lob-picker__search-field">
					<app-mat-select-search [data]="groups" propToCompare="title" propValue="id" [(val)]="active_group"
						label="Line of Business">
					</app-mat-select-search>
				</div>

				<div class="c-lob-picker__search-field">
					<app-mat-select-search [data]="companies" propToCompare="id" propValue="id" name="company_id"
						[(val)]="company_id" label="Company">
					</app-mat-select-search>
				</div>
			</div>
			<div class="c-lob-picker__search-footer">
				<button mat-stroked-button type="submit">Apply Filter</button>
				<button mat-stroked-button (click)="resetFilter($event)">Reset Filter</button>
			</div>
		</form>

		<div class="c-lob-picker__groups">
			<table mat-table [dataSource]="filteredGroups">
				<ng-container matColumnDef="group">
					<th mat-header-cell *matHeaderCellDef mat-sort-header> Line of Business </th>
					<td [class.active-group]="selectedGroupId == element.id" (click)="doSelectGroup(element.id)"
						mat-cell *matCellDef="let element">
						<span
							[class]="{lob: element.parent_id == 0, subgroup: element.parent_id > 0 }">{{element.title}}</span>
					</td>
				</ng-container>
				<ng-container matColumnDef="company">
					<th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;"> Company Name
					</th>
					<td [class.active-group]="selectedGroupId == element.id" (click)="doSelectGroup(element.id)"
						mat-cell *matCellDef="let element">
						<ng-container *ngIf="companiesById[element.company_id]">
							{{companiesById[element.company_id]['title']}}
						</ng-container>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
				<tr mat-row *matRowDef="let groups; columns: displayedColumns;"></tr>
			</table>
		</div>
	</div>

	<div *ngIf="resizable" class="resize-handle-right" mwlResizeHandle [enableGhostResize]="true" [resizeEdges]="{ right: true }"></div>
	<button *ngIf="resizable" mat-icon-button class="resize-button" (click)="toggleCompact()">
		<mat-icon>{{compact ? 'chevron_right' : 'chevron_left'}}</mat-icon>
	</button>
</div>