<div class="container">
  <h1 class="title" mat-dialog-title>{{title}}</h1>
  <ng-container *ngIf="!loading; else loadingPart">
    <button class="cross" mat-icon-button [mat-dialog-close]="false"><mat-icon>close</mat-icon></button>
  </ng-container>
  <ng-template #loadingPart>
    <mat-spinner diameter="40" class="cross"></mat-spinner>
  </ng-template>
  
  <!-- <div mat-dialog-content class="description">{{description}}</div> -->
  <form [formGroup]="notificationForm">
    <app-mat-select-search appearance="outline" [data]="groups" propToCompare="title" propValue="id" name="group"
        [formControl]="notificationForm.get('group_id')" label="Line of Business">
    </app-mat-select-search>
    <mat-form-field appearance='outline'>
      <mat-label>Note</mat-label>
      <textarea required formControlName="note" cols="60" rows="4" matInput></textarea>
      <mat-error *ngIf="notificationForm.get('note').invalid">{{getFirstFormError(notificationForm, 'note')}}
      </mat-error>
    </mat-form-field>
  </form>
  <div mat-dialog-actions class="actions" style="margin-bottom: 0;">
    <button mat-raised-button color="primary" (click)="send()">Send</button>
  </div>
</div>