import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiAdminService } from '@app/share/api-admin.service';
import * as moment from "moment";

export interface MatDialogData {
  title: string,
  description: string
}

@Component({
  selector: 'timetable-day-seat.component',
  templateUrl: 'timetable-day-seat.component.html',
  styleUrls: ['timetable-day-seat.component.css']
})
export class TimetableDaySeatComponent {
  group_id: any;
  selected_date: any;
  selected_seat_type: any;
  isLoading: boolean = true;
  description: string = "";
  rows = [];
  displayedColumns = ['first_name', 'last_name', 'start_datetime', 'end_datetime'];

  constructor(
    public dialogRef: MatDialogRef<TimetableDaySeatComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected api: ApiAdminService,
  ) {
    this.selected_date = data.selectedDate;
    this.group_id = data.group_id;
    this.selected_seat_type = data.type;
  }

  ngOnInit(): void {
    const condition = {
      group_id: this.group_id,
      date: moment(this.selected_date).format("YYYY-MM-DD"),
      type: this.selected_seat_type
    }
    this.isLoading = true;
    this.api.send('timetable/day-seat', {}, { ...condition }).then((res: any) => {
      this.rows = res['rows'];
      this.isLoading = false;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  get reportFileLink() {
    return this.api.to('timetable/day-report', { selected_date: this.selected_date, group_id: this.group_id, type: this.selected_seat_type }, true);
  }
}