<h2 mat-dialog-title>Profile Photo</h2>
<mat-dialog-content>
    <div class="wrp-cropped">
        <image-cropper
            [imageFile] = "file"
            [maintainAspectRatio]="true"
            [aspectRatio]="1/1"
            format="jpeg"
            (imageCropped)="imageCropped($event)"
            roundCropper="true"
            resizeToWidth="300"
            resizeToHeight="300"
        ></image-cropper>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div>
    </div>
    <div>
        <button mat-raised-button mat-dialog-close >Cancel</button>
        <button mat-raised-button  color="primary" (click)="saveImg()" [mat-dialog-close]="true">Save</button>
    </div>
</mat-dialog-actions>