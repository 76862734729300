import { PipeTransform, Pipe } from '@angular/core';

const week = 604800;
const day = 86400;
const hour = 3600;
const minute = 60;
const vocabolary = {
    weeks : 'w',
    days : 'd',
    hours : 'h',
    minutes : 'm',
}


@Pipe({name: 'secondsToTime'})
export class SecondsToTimePipe {
    transform(seconds){
        if (seconds === null || typeof seconds === 'undefined') {
            return null;
        } else if (seconds <= 60) {
            return "less than 1 min";
        }

        const time = {
            weeks: 0,
            days: 0,
            hours: 0,
            minutes: 0,
        }
        
        time.weeks = Math.floor(seconds / week);
        seconds -= time.weeks * week;
        time.days = Math.floor(seconds / day);
        seconds -= time.days * day;
        time.hours = Math.floor(seconds / hour);
        seconds -= time.hours * hour;
        time.minutes = Math.floor(seconds / minute);
        seconds -= time.minutes * minute;

        const result = [];
        for (const part of ['weeks','days','hours','minutes','seconds']) {
            if (time[part] > 0 || part == 'hours') {
                result.push(`${time[part]}${vocabolary[part]}`);
            }
        }

        return result.join(' ');
    }
}