import { Injectable } from '@angular/core';
import { HttpService } from '../core/http.service';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import IApi from './IApi';

@Injectable()
export class ApiSchedulingService implements IApi {
    constructor(
        private http: HttpService,
        private router: Router,
    ) {}

    get baseUrl() {
        return environment.API_BASE_URL  + '/scheduling';
    }

    send(url, get = null, post = null) {
        return this.http.send(this.baseUrl + '/' + url, get, post).catch(er => {
            if (er['status'] == 401) {
                this.router.navigate(['/scheduling/login']);
            }
        });
    }

    get(url, get_params = null) {
        return this.http.exec('GET', this.baseUrl + '/' + url, get_params);
    }

    post(url, get_params = null, post_params = null) {
        return this.http.exec('POST', this.baseUrl + '/' + url, get_params, post_params);
    }

    to (url, get_params = {}, use_token = true) {
        return this.http.to(this.baseUrl + '/' + url, get_params, use_token);
    }
}
