import { Injectable } from '@angular/core';
import { HttpService } from '../core/http.service';
import { environment } from '../../environments/environment';
import { ApiGeneralService } from './api-general.service';

@Injectable()
export class LogoService {
    default_light_path = '/assets/pic/logo_white.svg';
    default_dark_path = '/assets/pic/logo.svg';

    constructor(private api: ApiGeneralService) {
        this.updateLogoPath();
    }

    loading: boolean = true;
    light_path = null;
    dark_path = null;

    updateLogoPath() {
        this.loading = true;
        this.api.send('file/get-logos-url', {}).then(res => {
            this.light_path = res['light'] != null ? this.getFileUrlByFullName(res['light']) : this.default_light_path;
            this.dark_path = res['dark'] != null ? this.getFileUrlByFullName(res['dark']) : this.default_dark_path;
            this.loading = false;
        }).catch(res => {
            this.light_path = this.default_light_path;
            this.dark_path = this.default_dark_path;
            this.loading = false;
        })
    }

    getFileUrlByFullName(filename : string) : string {
        return `${environment.API_BASE_URL}/../storage/logo/${filename}`;
    }
}
