import { Component, OnInit, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-timeline-dimension',
  templateUrl: './timeline-dimension.component.html',
  styleUrls: ['./timeline-dimension.component.scss']

})
export class TimelineDimensionComponent implements OnInit {
  @HostBinding('style.width') hostWidth;
  @HostBinding('style.padding-left') hostPaddingLeft;
  @HostBinding('style.padding-right') hostPpaddingRight;

  start = '0:00';
  end   = '24:00'
  step  = 15;
  stepWidthPx = 20;
  leftPadding = 20;
  rightPadding = 20;

  startMinutes = 0;
  endMinutes = 0;

  @Input('curMinutes') curMinutes;

  items = [];

  constructor() { }

  ngOnInit(): void {
    this.start.split(':').forEach((val, index) => {
      this.startMinutes += parseInt(val, 10) * (index == 0 ? 60 : 1);
    });

    this.end.split(':').forEach((val, index) => {
      this.endMinutes += parseInt(val, 10) * (index == 0 ? 60 : 1);
    });

    var i = 0;

    for (var z = this.startMinutes; z <= this.endMinutes; z += this.step) {
      var h24 = Math.floor(z / 60);
      var m = (z - h24 * 60) % 60;
      var h12 = h24 > 12 ? h24 - 12 : h24;
      var a = h24 > 12 ? 'pm' : 'am';

      var left = i * this.stepWidthPx + this.leftPadding;

      this.items.push({
        h24: h24,
        h12: h12,
        m: m,
        min: (m < 10 ? '0' : '') + m,
        a: a,
        i: i,
        left: left,
      });

      i++;
    }

    this.hostWidth = this.fullWidth + 'px';
    this.hostPaddingLeft = this.leftPadding + 'px';
    this.hostPpaddingRight = this.rightPadding + 'px';
  }

  get fullWidth() {
    return Math.ceil((this.endMinutes - this.startMinutes) / this.step) * this.stepWidthPx + this.leftPadding + this.rightPadding;
  }

  minutes_x(minutes) {
    return (minutes / this.step) * this.stepWidthPx;
  }
}
