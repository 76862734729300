import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiAdminService } from "@app/share/api-admin.service";
import { CustomValidators, GetFirstCustomError } from "@app/share/custom-validators";

export interface ExternalExportDialogComponentData {
  title: string,
}

@Component({
  selector: 'external-export-dialog.component',
  templateUrl: 'external-export-dialog.component.html',
  styleUrls: ['external-export-dialog.component.css']
})
export class ExternalExportDialogComponent implements OnInit {
  title: string = "Export seats";
  group_id = 0;
  loading = false;
  loadingClient = false;
  clients = null;
  form = new FormGroup({
    export_all: new FormControl(true, []),
    client_id: new FormControl(null, []),
    date_from: new FormControl(null, []),
    date_to: new FormControl(null, []),
  });

  constructor(
    public dialogRef: MatDialogRef<ExternalExportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ExternalExportDialogComponentData,
    public api: ApiAdminService
  ) {
  }

  ngOnInit() {
    this.loadingClient = true;
    this.api.send('clients/all').then(res => {
      if (Array.isArray(res)) {
        for (const client of res) {
          client['title'] = client['name'];
        }
        this.clients = res;
      }
      this.loadingClient = false;
    }).then(res => {
      this.loadingClient = false;
    });
  }

  get exportUrl() : string {
    return this.api.to('clients/export-seats', this.form.value);
  }

  getFirstFormError(formGroup: FormGroup, controlName: string): string {
    return GetFirstCustomError(formGroup, controlName);
  }
}