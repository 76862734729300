import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface MatDialogData {
  title: string,
  description: string,
  errors: Array<string>
}

@Component({
  selector: 'alert-error.component',
  templateUrl: 'alert-error.component.html',
  styleUrls: ['alert-error.component.css']
})
export class AlertErrorComponent {
  errors: Array<string> = [];
  constructor(
    public dialogRef: MatDialogRef<AlertErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatDialogData
  ) { 
    if (data.errors){
      this.errors = data.errors
    }
    if (data.description) {
      this.errors.push(data.description);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}