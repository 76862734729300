import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'analog-watch',
  templateUrl: './analog-watch.component.html',
  styleUrls: ['./analog-watch.component.scss']
})
export class AnalogWatchComponent implements OnInit {

  @ViewChild('hrHand', { static: false }) hrHand: ElementRef;
  @ViewChild('minHand', { static: false }) minHand: ElementRef;
  @ViewChild('secHand', { static: false }) secHand: ElementRef;

  constructor() { }

  ngOnInit(): void {
  }

  @Input()
  set momentEntry(moment: moment.Moment) {
    if (this.secHand != null) {
      this.secHand.nativeElement.style.transform = `rotate(${moment.get('seconds') * 6}deg)`;
    }
    if (this.minHand != null) {
      this.minHand.nativeElement.style.transform = `rotate(${moment.get('minutes') * 6}deg)`;
    }
    if (this.hrHand != null) {
      this.hrHand.nativeElement.style.transform = `rotate(${moment.get('hours') * 30 + moment.get('minutes') * 0.5}deg)`;
    }
  }
}
