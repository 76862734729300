import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Title } from '@angular/platform-browser';

@Injectable()
export class MetaPageService {

    static _metaTitle;

    constructor (
        private title: Title
    ) {
    }

    setTitle (name) {
        MetaPageService._metaTitle = name;
        this.title.setTitle(environment.APP_NAME + ' | ' + name);
    }

    get metaTitle() {
        return MetaPageService._metaTitle;
    }
}
