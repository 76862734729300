import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-rating-star',
  templateUrl: './rating-star.component.html',
  styleUrls: ['./rating-star.component.scss'],
  inputs: ['total', 'step', 'displayOnly'],
  outputs: ['change'],
})
export class RatingStarComponent implements OnInit {

  constructor() { }

  @Input() total: number = 5;

  // <app-rating-star displayOnly="true">
  @Input() displayOnly: boolean = false;
  @Input() step: number = 0.5;
  @Input() currentValue: number = 0;
  @Output() change : EventEmitter<number> = new EventEmitter<number>();

  stars = [];

  ngOnInit(): void {
      for (let i = 0; i <= Math.floor(this.total / this.step); i++) {
        const value = i * this.step;
        this.stars.push({
          value: this.total - value,
          half: Number(value) === value && value % 1 !== 0
        });
      }
  }

  onChange(value) {
    if (!this.displayOnly) {
      this.currentValue = value;
      this.change.emit(value)
    }
  }
}
