import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileUploadService } from '@app/core/file-upload-service';
import { AlertMatDialogComponent } from '@app/modal/alert-mat-dialog/alert-mat-dialog.component';
import { environment } from '@env/environment';

export interface ImportUsersData {
  
}

@Component({
  selector: 'import-logo-dialog',
  templateUrl: './import-logo-dialog.component.html',
  styleUrls: ['./import-logo-dialog.component.scss']
})
export class ImportLogoDialogComponent implements OnInit {

  constructor(
    public fileUploadService : FileUploadService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AlertMatDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ImportUsersData
  ) { }

  errors : string[] = [];
  success : string[] = [];
  loading : boolean = false;

  ngOnInit(): void {
    
  }

  onFileUpload(event : FileList | any) {
    let file : File;
    if (event instanceof FileList) {
      file = event[0];
    } else if (event instanceof Event && event.target instanceof HTMLInputElement) {
      file = event.target.files[0];
    }
    if (["png", "jpg", "jpeg", "svg", "webp"].indexOf(file['name'].split('.').pop()) < 0) {
      this.errorFileAlert('extension');
      if (event.target) {
        event.target.value = '';
      }
      return;
    } else if (file['size'] > 50 * 1024 * 1024) {
      this.errorFileAlert('size');
      if (event.target) {
        event.target.value = '';
      }
      return;
    }
    this.errors = [];
    this.loading = true;
    this.fileUploadService.upload(environment.API_BASE_URL + '/general/file/upload-logo', [file], {}).then(res => {
      this.loading = false;
      this.dialogRef.close(res);
    }).catch(resRaw => {
      this.loading = false;
      if (typeof resRaw == 'string') {
        this.errors = JSON.parse(resRaw)?.errors ?? ["Unkown error occured! Use template for uploading logo."];
      }
    });
  }

  errorFileAlert(type: string) {
    let title = "File uploading error";
    let description = "";
    if (type == "extension") {
      description = "File must be png|jpg|jpeg|svg|webp only.";
    } else if (type == "size") {
      description = "File size must be less than 50mb.";
    } else if (type == "loading") {
      description = "Wait untill all files will be fully downlnoad.";
    }
    this.dialog.open(AlertMatDialogComponent, { "data": { title, description } });
  }
}
