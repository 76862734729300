import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ConfirmMatDialogComponent } from '../confirm-mat-dialog/confirm-mat-dialog.component';
import { SharedService } from './shared.service';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatSelectMappingComponent } from '@app/admin/share/mat-select-mapping/mat-select-mapping.component';
import { CustomValidators } from '@app/share/custom-validators';

export interface MatTemplateDialogData {
    columnsToFormat: Array<any>;
}

@Component({
    selector: 'template-import-dialog',
    templateUrl: 'template-import-dialog.component.html',
    styleUrls: ['template-import-dialog.component.scss'],
    providers: [{
        provide: STEPPER_GLOBAL_OPTIONS,
        useValue: { displayDefaultIndicatorType: false }
    }]
})

export class TemplateImportDialog implements OnInit {
    @ViewChild('stepper') stepper: MatStepper;
    @ViewChild(UploadFileComponent) uploadFileComponent: UploadFileComponent;
    @ViewChild(MatSelectMappingComponent) matchFieldsComponent: MatSelectMappingComponent;
    selectedFileType: string | null = null;
    fileUploaded: boolean = false;
    file: File;
    isLinear: boolean = true;
    columnsToFormat: Array<any>;
    mappedFields: Array<any>;
    btnText: string = 'Next';
    public errors: string[] = [];
    public errorTooltip: any;
    public loading: boolean = false;
    disableNext: boolean = false;

    formGroup: FormGroup = new FormGroup({
        forecast: new FormControl(0, [Validators.required, CustomValidators.Integer]),
        addToExistingSchedule: new FormControl(false)
    })


    constructor(
        private dialogRef: MatDialogRef<TemplateImportDialog>,
        private dialog: MatDialog,
        private sharedService: SharedService,
        @Inject(MAT_DIALOG_DATA) public data: MatTemplateDialogData
    ) {
        this.columnsToFormat = data.columnsToFormat;
    }

    ngOnInit() {
        this.sharedService.uploadedFile$.subscribe(file => {
            this.file = file;
        })
    }

    goToPreviousStep() {
        this.stepper.previous();
    }

    goToNextStep() {
        this.stepper.next();
    }

    // Function to check whether the "Next" button should be disabled
    isNextButtonDisabled(): boolean {
        return this.selectedFileType === null || (this.stepper.selectedIndex === 1 && !this.fileUploaded) || this.errors.length > 0;
    }

    onStepChange(event: any): void {
        this.btnText = this.stepper.selectedIndex == 3 && this.errors.length == 0 ? 'Import' : 'Next';
    }

    // Function to be called when file is uploaded
    onFileUploaded(fileUploaded: boolean): void {
        this.fileUploaded = fileUploaded;
        this.errors = [];
    }

    onFileTypeSelected(fileType: string): void {
        this.disableNext = false;
        this.fileUploaded = false;
        this.errors = [];
        // Use the SharedService to set the selected file type
        this.sharedService.setSelectedFileType(fileType);

        // Check if uploadFileComponent is available before calling removeFile
        if (this.uploadFileComponent) {
            this.uploadFileComponent.removeFile(fileType);
        }
    }

    onClose(): void {
        this.dialog.open(ConfirmMatDialogComponent, {
            data: {
                title: 'Leave Import Data',
                description: 'You will lose any unsaved changes'
            }
        }).afterClosed().subscribe(result => {
            if (result)
                this.dialogRef.close();
        })
    }

    onErrorFieldsChange(errorFields: string[]) {
        this.errors = errorFields;
        this.errorTooltip = "Error: The following fields must be selected " + errorFields.join(', ');
    }

    getMappedFields(mappedFields: string[]) {
        this.mappedFields = mappedFields;
    }

    loadingData(loading: boolean) {
        this.loading = loading;
    }

    submit() {
        this.dialogRef.close({
            code: 200,
            file: this.file,
            loading: this.loading,
            mapped_array: this.mappedFields
        });
    }

    disableNextBtn(nxtBtnDisabled: boolean): void {
        this.disableNext = nxtBtnDisabled;
    }
}