import { Injectable } from '@angular/core';
import { ApiAdminService } from '@app/share/api-admin.service';
import { AppStorageService } from '@app/core/app-storage.service';

@Injectable()
export class StatisticsService {
  static _entities: {
    users: {
      pending: number
    }
  } = {
    users: { pending: 0 },
  };

  private storeKey = '__stats.service_';

  constructor(
    private api: ApiAdminService,
    private storage: AppStorageService,
  ) {
    const entities = this.getStoreParam('entities');
    if (entities != null) {
      StatisticsService._entities = entities;
    }
  }

  updateEntities() {
    this.api.send('statistics/entities').then(res => {
      // @ts-ignore: Unreachable code error
      StatisticsService._entities = res;
      this.setStoreParam('entities', res);
    });
  }

  private getStoreParam(key: string) {
    return this.storage.getParam(this.storeKey + key);
  }

  private setStoreParam(key: string, value: any) {
    return this.storage.setParam(this.storeKey + key, value);
  }

  get entities() {
    return StatisticsService._entities;
  }
}
