import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@app/core/core.module';
import { RouterModule } from '@angular/router';

// Other Components
import { UserMenuComponent } from './user-menu/user-menu.component';
import { ArraySortPipe } from './array-sort-pipe';
import { StatisticsService } from './services/statistic.service';
import { ColorService } from './color.service';
import { UserImgLaodComponent } from './user-img/user-img-laod/user-img-laod.component';
import { UserImgModalComponent } from './user-img/user-img-modal/user-img-modal.component';

import { ImageCropperModule } from 'ngx-image-cropper';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from "@angular/material/table";
// import { UserImgServices} from './services/user-img.services';
import { RatingStarComponent } from './rating-star/rating-star.component';
import { MatSelectSearchComponent } from './mat-select-search/mat-select-search.component';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';

import { SearchComponent } from './search/search.component';

import { PageEvent } from '@angular/material/paginator';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { WoSearchPaginatorService } from './search/wo-search-paginator.service';

import { LobPickerComponent } from './lob-picker/lob-picker.component';
import { ResizableModule } from 'angular-resizable-element';

import { TimelineDimensionComponent } from './timeline-dimension/timeline-dimension.component';
import { TimelinePickerComponent } from './timeline-picker/timeline-picker.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { SecondsToTimePipe } from './seconds-to-time.pipe';
import { SortByPipe } from './order-by-pipe';
import { WeekPickerComponent } from './week-picker/week-picker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MonthPickerComponent } from './month-picker/month-picker.component';
import { DayPickerComponent } from './day-picker/day-picker.component';
import { TimezonedClockComponent } from './timezoned-clock/timezoned-clock.component';
import { WorkStatusIconComponent } from './work-status-icon/work-status-icon.component';
import { AnalogWatchComponent } from './analog-watch/analog-watch.component';
import { AnalogWatchPanelComponent } from './analog-watch-panel/analog-watch-panel.component';
import { LogoComponent } from './logo/logo.component';
import { ClientCodePanelComponent } from './client-code-panel/client-code-panel.component';
import { NgxMaskModule } from 'ngx-mask';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatSelectMappingComponent } from './mat-select-mapping/mat-select-mapping.component';

@NgModule({
    declarations: [
        UserMenuComponent,
        ArraySortPipe,
        ClientCodePanelComponent,
        UserImgLaodComponent,
        UserImgModalComponent,
        RatingStarComponent,
        MatSelectSearchComponent,
        SearchComponent,
        LobPickerComponent,
        TimelineDimensionComponent,
        TimelinePickerComponent,
        SecondsToTimePipe,
        WeekPickerComponent,
        MonthPickerComponent,
        DayPickerComponent,
        SortByPipe,
        TimezonedClockComponent,
        WorkStatusIconComponent,
        AnalogWatchComponent,
        AnalogWatchPanelComponent,
        LogoComponent,
        MatSelectMappingComponent
    ],
    imports: [
        CommonModule,
        CoreModule,
        RouterModule,
        ImageCropperModule,
        MatDialogModule,
        MatButtonModule,
        MatCheckboxModule,
        MatRippleModule,
        MatIconModule,
        MatTooltipModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        ReactiveFormsModule,
        FormsModule,
        MatTableModule,
        ResizableModule,
        DragDropModule,
        MatMenuModule,
        NgxMaskModule.forRoot(),
        ClipboardModule
    ],
    exports: [
        UserMenuComponent,
        ArraySortPipe,
        UserImgLaodComponent,
        UserImgModalComponent,
        RatingStarComponent,
        MatSelectSearchComponent,
        SearchComponent,
        LobPickerComponent,
        TimelineDimensionComponent,
        TimelinePickerComponent,
        WeekPickerComponent,
        MonthPickerComponent,
        DayPickerComponent,
        SecondsToTimePipe,
        SortByPipe,
        TimezonedClockComponent,
        WorkStatusIconComponent,
        AnalogWatchPanelComponent,
        LogoComponent,
        ClientCodePanelComponent,
        MatSelectMappingComponent
    ],
    providers: [
        StatisticsService,
        ColorService,
        PageEvent,
        {
          provide: MatPaginatorIntl,
          useClass: WoSearchPaginatorService
        }
    ]
})
export class ShareModule { }
