<mat-form-field appearance="{{appearence}}" class="map-select" [ngClass]="{ 'custom-warning-border': shouldApplyWarningBorder() || isDuplicate }">
    <span matSuffix *ngIf="loading">
        <mat-spinner diameter="24"></mat-spinner>
    </span>
    <mat-select [required]="required" [disabled]="disabled" [attr.name]="name" disableOptionCentering
        [panelClass]="'myPanelClass'" (selectionChange)="valChanged($event.value)" [(ngModel)]="val">
        <mat-optgroup label="Options">
            <mat-option value="skip">--Skip Import</mat-option>
        </mat-optgroup>
        <mat-optgroup label="Fields">
            <mat-option *ngFor="let item of getData" [value]="item.name">
                {{item.label}}
            </mat-option>
        </mat-optgroup>
    </mat-select>
</mat-form-field>