import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserImgServices { 

    public file; 
   
    private sharedData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    sharedData$: Observable<any> = this.sharedData.asObservable();
  
    setSharedData(sharedData) {
      this.sharedData.next(sharedData);
    }
   
    setFile(file) {
        this.file = file;
    }

    getFile() {
        return this.file;    
    }
}