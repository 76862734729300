<div class="wo-search">
    <section class="content-header">
        <h1>{{metaPage.metaTitle}}</h1>
    </section>

    <div class="content">
        <div class="box">
            <div class="box-body">
                <form action="javascript:void(0);" (submit)="doSearch()">
                    <div class="form__search-box" [ngClass]="{'advanced-filter': advanced_filters}">
                        <div class="search-box">
                          <mat-form-field>
                            <mat-label>Search</mat-label>
                            <input name="common_search" [(ngModel)]="search['inpCommon']" matInput>
                            <button mat-icon-button matSuffix>
                              <i class="fa fa-search" aria-hidden="true"></i>
                            </button>
                          </mat-form-field>
                          <a href="javascript:void(0);" (click)="toggleAdvancedFilter()" class="form__search-box__advanced-btn">
                            <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                            Advanced Search
                          </a>
                        </div>

                        <div class="form__advanced-search-box form__advanced-search-box_borderless">
                          <section class="form__advanced">

                            <ng-content select="[searchAdvanced]"></ng-content>

                            <div class="advanced__controls">
                              <button mat-stroked-button>Apply Filter</button>
                              <button mat-stroked-button (click)="resetFilter($event)">Reset Filter</button>
                            </div>
                          </section>
                        </div>


                    </div>
                </form>

                <div class="c-groups__top_buttons">
                    <ng-content select="[searchGroupButtons]"></ng-content>
                </div>

                <div class="table__container" [ngClass]="{'box-loading': isLoading}">
                    <ng-content select="[searchResult]"></ng-content>
                    <div class="nothing-found"><small *ngIf="rows.length == 0">Nothing found</small></div>
                </div>


                <mat-paginator
                    *ngIf="total_page_items > min_limit"
                    [pageSizeOptions]="pageSizeOptions"
                    [pageSize]="pageSize"
                    [length]="total_page_items"
                    [pageIndex]="curr_page"
                    aria-label="Select page"
                    showFirstLastButtons
                    (page)="pageEvent = getPaginatorData($event)"
                ></mat-paginator>

                <!-- <wo-paginator *ngIf="last_page>1" [last]="last_page" [current]="curr_page" (changePage)="toPage($event)"></wo-paginator> -->
            </div>
        </div>
    </div>
</div>
