import { Component, Inject, OnInit, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiSchedulingService } from '@app/share/api-scheduling.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';

export interface MatDialogData {
  group_id: number;
  startDate: string;
  endDate: string;
  minDate: string;
}


@Component({
  selector: 'app-select-employee',
  templateUrl: './select-employee.component.html',
  styleUrls: ['./select-employee.component.scss']
})
export class SelectEmployeeComponent implements OnInit {
  group_id: number = 0;
  defaultStartDate = '';
  defaultEndDate = '';
  minDate = '';

  button_confirm: string = "Confirm";
  button_cancel: string = "Cancel";
  users:Array<{id, title}> = [];


  form: UntypedFormGroup = new UntypedFormGroup({
    selected_user: new UntypedFormControl(0, [Validators.required, Validators.min(1)]),
    start_time: new UntypedFormControl('', [Validators.required]),
    end_time: new UntypedFormControl('', [Validators.required])
  });

  constructor(
    public dialogRef: MatDialogRef<SelectEmployeeComponent>,
    private api: ApiSchedulingService,
    @Inject(MAT_DIALOG_DATA) public data: MatDialogData) { 
    this.group_id = data.group_id;
    this.defaultStartDate = data.startDate;
    this.defaultEndDate = data.endDate;
    this.minDate = data.minDate;
  }

  ngOnInit(): void {
    this.api.post('group/group-users', {}, {group_id: this.group_id, date: moment(new Date(this.defaultStartDate)).format('MM/DD/YYYY')}).then((res: Array<{id, first_name, last_name}>) => {
      let users = [];
      for(const user of res){
        users.push({
          id: user['id'],
          title: user['first_name']+' '+user['last_name']
        })
      }
      this.users = users;
    });
    this.form.patchValue({
      start_time: this.defaultStartDate,
      end_time: this.defaultEndDate
    })
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onUserSelected(): void {

  }

  confirm(): void {
    this.dialogRef.close({      
      user_id: this.form.get('selected_user').value,
      dateFrom: this.form.get('start_time').value,
      dateTo: this.form.get('end_time').value
    })
  }

}
