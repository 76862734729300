import { Component } from '@angular/core';
import { WoBreadCrumbsService } from '@app/wo-module/wo-breadcrumbs/wo-breadcrumbs.service';
import { MetaPageService } from '../../core/meta-page.service';

@Component({
    templateUrl: 'domain-not-found.componetn.html',
    styleUrls: ['domain-not-found.component.css'],
})
export class DomainNotFoundComponent {
    constructor (
        private breadcrumbs: WoBreadCrumbsService,
        private metaPage: MetaPageService,
    ) {
        this.metaPage.setTitle('Page not found');
    }
}
