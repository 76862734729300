import { Component, OnInit, ViewChild, ElementRef, Input} from '@angular/core';
import { UserImgModalComponent } from '../user-img-modal/user-img-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { UserImgServices } from '../../services/user-img.services';
import { ApiAdminService } from '@app/share/api-admin.service';
import { WoFlashService } from "@app/wo-module/wo-flash/wo-flash.service";
import { ConfirmMatDialogComponent } from "@app/modal/confirm-mat-dialog/confirm-mat-dialog.component";

@Component({
  selector: 'app-user-img-laod',
  templateUrl: './user-img-laod.component.html',
  styleUrls: ['./user-img-laod.component.css']
})
export class UserImgLaodComponent implements OnInit {
  @ViewChild('file') input: ElementRef<HTMLInputElement>;
  @Input() urlImg: string;

  public url: any = null;

  constructor(
    public dialog: MatDialog,
    public userImgServices: UserImgServices,
    public api: ApiAdminService,
    protected woFlash: WoFlashService
  ) {

   }

  ngOnInit(): void {
    this.userImgServices.sharedData$.subscribe(function(data) {
      this.url = data
    }.bind(this));

    this.userImgServices.setSharedData(this.urlImg);
  }

  upload() {
    this.input.nativeElement.click();
  } 

  fileChangeEvent(event) {
      this.woFlash.hide();
      const file = event.target.files[0];
      const type = file.type.split('/')[1];
      if (['png', 'jpg', 'jpeg'].indexOf(type) !== -1) {
        this.userImgServices.setFile(file);
        this.dialog.open(UserImgModalComponent);
      } else {
        this.woFlash.addError('Fail uploading.(File must be .png, .jpg, .jpeg only)');
        this.woFlash.show();
      }
  }

  delete() {

    const dialogRef = this.dialog.open(ConfirmMatDialogComponent, {
      data: {
        title: "Delete",
        description: "Are you sure you want to delete the photo?"
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.api.send('user/avatar-delete').then(res => {
            if(res['code'] == 203) {
                this.userImgServices.setSharedData('');
            }
          });
        }
     });
  }
}
