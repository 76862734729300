import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HttpService } from '@app/core/http.service';
import { AuthService } from '@app/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { WoFlashService } from '@app/wo-module/wo-flash/wo-flash.service';
import { environment } from '../../../environments/environment';
import { ChatService } from '@app/share/chat.service';

@Component({
    templateUrl: 'password-reset.component.html',
    styleUrls: ['password-reset.component.css']
})

export class PasswordResetComponent {
    password = '';
    password_repeat = '';
    formErrors: any;
    isLoading = false;
    token: string;
    isValidToken: boolean = null;

    constructor (
        private title: Title,
        private http: HttpService,
        private auth: AuthService,
        private activeRoute: ActivatedRoute,
        private router: Router,
        protected woFlash: WoFlashService,
        private chat: ChatService,
    ) {
        this.title.setTitle('Login');
        this.activeRoute.params.subscribe(params => {
            this.token = params['token'];
            this.checkToken();
        })
    }

    checkToken() {
        this.http.send(
            environment.API_BASE_URL + '/general/auth/password-reset',
            {token: this.token},
        ).then((res) => {
            this.isValidToken = res['valid'];
        });
    }

    doSend() {
        if (this.isLoading === true) {
            return false;
        }
        this.isLoading = true;
        this.formErrors = {};
        this.http.send(
            environment.API_BASE_URL + '/general/auth/password-reset',
            {token: this.token},
            {password: this.password, password_repeat: this.password_repeat},
        ).then((res) => {
            this.isLoading = false;
            if (!res['errors']) {
                this.woFlash.addMessage('The operation was successfully done!');
                this.auth.setToken(res['access_token'], res['expires_in']);
                this.auth.setRefreshToken(res['refresh_token']);
                this.auth.setUser(res['user']);
                this.chat.login();
                this.router.navigate(['/admin']);
            } else {
                setTimeout(() => {
                    this.formErrors = res['errors'];
                }, 200);
            }
        }).catch(res => {
            this.formErrors = {};
            this.isLoading = false;
            alert('Oops! Something went wrong.');
        });
    }
}
