<div class="tl-pm-bg"></div>
<div class="tl-pm-bg tl-pm-bg_right"></div>
<div
	*ngFor="let item of items"
	class="tl-item"
	[class.is-hour]="item.m === 0"
	[style.left.px]="item.left"
>
<div *ngIf="item.m === 0" class="tl-label__hour">{{item.h12}}</div>
<div *ngIf="item.m !== 0" class="tl-label__minute">{{item.min}}</div>
</div>
<div class="tl-curr-time" *ngIf="curMinutes != null" [style.left.px]="minutes_x(curMinutes) + leftPadding" matTooltip="Now" matTooltipPosition="above"></div>

