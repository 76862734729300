import { Component, Input } from '@angular/core';

@Component({
  selector: 'work-status-icon',
  templateUrl: './work-status-icon.component.html',
  styleUrls: ['./work-status-icon.component.scss']
})
export class WorkStatusIconComponent {
  @Input('code') code;

  constructor() { }

}
