import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-mat-select-mapping',
    templateUrl: './mat-select-mapping.component.html',
    styleUrls: ['./mat-select-mapping.component.scss']
})

export class MatSelectMappingComponent implements OnInit, OnDestroy {
    @Input()
    val: string | Array<string> = '';
    @Input()
    propToCompare: string;
    @Input()
    name: string;
    @Input()
    required: boolean = false;
    @Input()
    propValue: string;
    @Input()
    label: string;
    @Input()
    appearence: 'legacy' | 'standard' | 'fill' | 'outline' = 'legacy';
    @Input()
    disabled: boolean = false;
    @Output()
    valChange = new EventEmitter<string>();
    @Input()
    loading: boolean = false;
    @Input()
    data = [];
    @Input()
    isDuplicate: boolean = false;

    constructor() { }

    ngOnInit(): void { }

    ngOnDestroy(): void { }

    valChanged(value: any) {
        this.valChange.emit(value);
    }

    get getData() {
        return this.data;
    }

    get isSkipSelected(): boolean {
        return this.val === 'skip';
    }

    shouldApplyWarningBorder(): boolean {
        // Determine whether to apply the warning border based on your conditions
        return this.isSkipSelected;
    }

    get getNotSelectedRequiredFields(): string {
        return 'test';
        // const notSelectedFields: string[] = [];

        // Iterate through required fields and check if they are not selected
        // for (const field of this.data) {
        //     if (this.val !== field.name) {
        //         notSelectedFields.push(field);
        //     }
        // }

        // return notSelectedFields;
    }
}