import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class WoFlashService {
    errorMessages: string[] = [];
    successMessages: string[] = [];
    warningMessages: string[] = [];
    flashchange = new BehaviorSubject<Object>(false);

    addError (msg: any) {
        if (typeof msg === 'string') {
            this.errorMessages.push(msg);
        } else if (typeof msg === 'object') {
            Object.keys(msg).map(key => {
                this.errorMessages.push(msg[key]);
            });
        }
    }

    addWarning (msg) {
        if (typeof msg === 'string') {
            this.warningMessages.push(msg);
        } else if (typeof msg === 'object') {
            Object.keys(msg).map(key => {
                this.warningMessages.push(msg[key]);
            });
        }
    }

    addMessage (msg: any) {
        if (typeof msg === 'string') {
            this.successMessages.push(msg);
        } else if (typeof msg === 'object') {
            Object.keys(msg).map(key => {
                this.successMessages.push(msg[key]);
            });
        }
    }

    show(block = 'main') {
        window.scrollTo(0, 0);

        this.flashchange.next({
            errors: this.errorMessages,
            success: this.successMessages,
            warnings: this.warningMessages,
            block: block
        });

        this.flush();
    }

    hide() {
        this.flashchange.next({
            errors: [],
            success: [],
            warnings: [],
        });
    }

    flush () {
        this.errorMessages = [];
        this.successMessages = [];
        this.warningMessages = [];
    }

    geFlashChange() {
        return this.flashchange;
    }
}
