<div class="container">
  <button class="cross" mat-icon-button [mat-dialog-close]="true"><mat-icon>close</mat-icon></button>
    <div class="table__container">
      <ng-container [ngSwitch]="isLoading">
        <ng-container *ngSwitchCase="true">
          <mat-spinner [diameter]="30"></mat-spinner>
        </ng-container>
        <ng-container *ngSwitchCase="false">
          <table mat-table [dataSource]="rows">
            <ng-container matColumnDef="first_name">
              <th mat-header-cell *matHeaderCellDef>First Name</th>
              <td mat-cell *matCellDef="let element">
                 {{element.first_name}}
              </td>
            </ng-container>
            <ng-container matColumnDef="last_name">
              <th mat-header-cell *matHeaderCellDef>Last Name</th>
              <td mat-cell *matCellDef="let element">
                 {{element.last_name}}
              </td>
            </ng-container>
            <ng-container matColumnDef="start_datetime">
              <th mat-header-cell *matHeaderCellDef>Start</th>
              <td mat-cell *matCellDef="let element">
                 {{element.start_datetime_fmt}}
              </td>
            </ng-container>
            <ng-container matColumnDef="end_datetime">
              <th mat-header-cell *matHeaderCellDef>End</th>
              <td mat-cell *matCellDef="let element">
                 {{element.end_datetime_fmt}}
              </td>
            </ng-container>
            <ng-container matColumnDef="role">
              <th mat-header-cell *matHeaderCellDef>Role</th>
              <td mat-cell *matCellDef="let element">
                 {{ROLE_LABLES[element.item_name]}}
              </td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>Actions</th>
              <td mat-cell *matCellDef="let element">
                <button matTooltip="Cancel shift" mat-icon-button color="warn" (click)="onCancelSeat(element)">
                  <mat-icon>block</mat-icon>
              </button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <div *ngIf="rows.length == 0" style="padding: 20px; text-align: center; color: #666;">
               No added users
          </div>
          <a style="margin-top: 10px; width: 200px" mat-stroked-button [href]="reportFileLink" *ngIf="rows.length != 0">Download</a>
        </ng-container>
      </ng-container>
    </div>
</div>