import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { share } from 'rxjs/operators';
// import 'rxjs/add/operator/share';
@Injectable()
export class FileUploadService {
    private timeoutDuration = 1800000; // 30 minutes in milliseconds

    /**
     * @param Observable<number>
     */
    private progress$: Observable<number>;
    private progress = 0;
    private progressObserver: any;

    /**
     * Set interval for frequency with which Observable inside Promise will share data with subscribers.
     */
    private static setUploadUpdateInterval(interval: number): void {
        setInterval(() => { }, interval);
    }

    constructor(private auth: AuthService) {
        this.progress$ = new Observable(observer => {
            this.progressObserver = observer;
        });
    }

    public getProgress(): Observable<number> {
        return this.progress$;
    }

    /**
     * Upload files through XMLHttpRequest
     */
    // public upload(url: string, files, params = null): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         const formData: FormData = new FormData();
    //         const xhr: XMLHttpRequest = new XMLHttpRequest();

    //         if (Array.isArray(files)) {
    //             for (let i = 0; i < files.length; i++) {
    //                 formData.append('files[]', files[i], files[i].name);
    //             }
    //         } else {
    //             var num = 0;
    //             for (var i in files) {
    //                 formData.append(i, files[i], files[i].name);
    //                 num++;
    //             }
    //         }

    //         if (params !== null) {
    //             this.buildFormData(formData, params);
    //         }

    //         xhr.onreadystatechange = () => {
    //             if (xhr.readyState === 4) {
    //                 if (xhr.status === 200) {
    //                     resolve(JSON.parse(xhr.response));
    //                 } else {
    //                     reject(xhr.response);
    //                 }
    //             }
    //         };
    //         FileUploadService.setUploadUpdateInterval(100);
    //         xhr.upload.onprogress = (event) => {
    //             this.progress = Math.round(event.loaded / event.total * 100);
    //             if (this.progressObserver) {
    //                 this.progressObserver.next(this.progress);
    //             }
    //         };
    //         xhr.open('POST', url, true);
    //         xhr.setRequestHeader('Authorization', 'Bearer ' + this.auth.getToken());
    //         xhr.send(formData);
    //     });
    // }
    public upload(url: string, files, params = null): Promise<any> {
        return new Promise((resolve, reject) => {
            const formData: FormData = new FormData();
            const xhr: XMLHttpRequest = new XMLHttpRequest();

            if (Array.isArray(files)) {
                for (let i = 0; i < files.length; i++) {
                    formData.append('files[]', files[i], files[i].name);
                }
            } else {
                var num = 0;
                for (var i in files) {
                    formData.append(i, files[i], files[i].name);
                    num++;
                }
            }

            if (params !== null) {
                this.buildFormData(formData, params);
            }

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        resolve(JSON.parse(xhr.response));
                    } else {
                        reject(xhr.response);
                    }
                }
            };

            xhr.upload.onprogress = (event) => {
                this.progress = Math.round(event.loaded / event.total * 100);
                if (this.progressObserver) {
                    this.progressObserver.next(this.progress);
                }
            };

            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + this.auth.getToken());

            // Set a timeout
            xhr.timeout = this.timeoutDuration;
            xhr.ontimeout = () => {
                reject('Request timed out');
            };

            // Retry logic
            const sendRequest = (attempt = 1) => {
                xhr.send(formData);
                xhr.onerror = () => {
                    if (attempt < 3) {
                        console.log(`Retrying upload... attempt ${attempt + 1}`);
                        sendRequest(attempt + 1);
                    } else {
                        reject('Failed after 3 attempts');
                    }
                };
            };

            sendRequest();
        });
    }


    /**
     * Build data for FormData object.
     * @param formData object
     * @param data object
     * @param parentKey string
     */
    buildFormData(formData, data, parentKey = null) {
        if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
            Object.keys(data).forEach(key => {
                this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
            });
        } else {
            const value = data == null ? '' : data;
            formData.append(parentKey, value);
        }
    }
}
