<article class="container">
    <button mat-icon-button (click)="changeDay(-1)">
        <mat-icon>arrow_back_ios_new</mat-icon>
    </button>
    <mat-form-field [appearance]="appearance">
        <mat-label>Select Date</mat-label>
        <input readonly matInput [matDatepicker]="dp" placeholder="Date" [formControl]="date">
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp></mat-datepicker>
    </mat-form-field>
    <button mat-icon-button (click)="changeDay(+1)">
        <mat-icon>arrow_forward_ios</mat-icon>
    </button>
</article>