import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiAdminService } from "@app/share/api-admin.service";
import { CustomValidators, GetFirstCustomError } from "@app/share/custom-validators";

export interface MatDialogData {
  title: string,
  group_id: string | number,
  groups: []
}

@Component({
  selector: 'notification-mat-dialog.component',
  templateUrl: 'notification-mat-dialog.component.html',
  styleUrls: ['notification-mat-dialog.component.css']
})
export class NotificationMatDialogComponent implements OnInit {
  title: string = "Push Notification";
  group_id = 0;
  loading = false;
  groups = [];

  notificationForm = new UntypedFormGroup({
    "note": new UntypedFormControl("", [Validators.required, CustomValidators.WhiteSpaces]),
    "group_id": new UntypedFormControl("", [])
  });

  constructor(
    public dialogRef: MatDialogRef<NotificationMatDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatDialogData,
    public api: ApiAdminService
  ) { 
  }
  
  ngOnInit() {
    this.notificationForm.get('group_id').setValue(this.data.group_id);
    this.groups = this.data.groups;    
  }

  send() {
    if (this.notificationForm.invalid) {
      return;
    }

    this.loading = true;
    this.api.send("notifications/send-notification", {}, {
      ...this.notificationForm.value
    }).then(res => {
      if (res["code"] == 200) {
        this.dialogRef.close(true);
      }
      this.loading = false;
    }).catch(res => {
      this.loading = false;
    })
  }

  getFirstFormError(formGroup: UntypedFormGroup, controlName: string): string {
    return GetFirstCustomError(formGroup, controlName);
  }
}