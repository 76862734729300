import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedService } from '../shared.service';
import { FileUploadService } from '@app/core/file-upload-service';
import { ApiSchedulingService } from '@app/share/api-scheduling.service';
import { combineLatest, forkJoin } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { TemplateImportDialog } from '../template-import-dialog.component';
import { WoFlashService } from '@app/wo-module/wo-flash/wo-flash.service';

@Component({
    selector: 'app-match-fields',
    templateUrl: 'match-fields.component.html',
    styleUrls: ['./match-fields.component.scss']
})

export class MatchFieldsComponent implements OnInit {
    @Input()
    columnsToFormat: Array<any> = [];
    @Output()
    errorFieldsChange = new EventEmitter<string[]>();
    @Output()
    mappedFieldsArray = new EventEmitter<string[]>();
    @Output()
    isLoading = new EventEmitter<boolean>();
    @Output()
    disableNext = new EventEmitter<boolean>(false);
    public rows = [];
    public loading = false;
    public displayedColumns: string[] = ['your_field', 'alert', 'imin_field', 'field_preview'];
    public uploadedFile = null;
    public selectedFileType = '';
    public iminFields: string[] = [];
    public errorFields: string[] = [];
    public missingFieldTooltip: string = "Missing Field: Match this data with a field in ImIn. Otherwise this data won't be imported";
    public duplicateFieldTooltip: string = "Review duplicate matches. Each ImIn field can be matched to only one row";
    public mappedText: string = '';
    unsupported_type_err: string = null;

    constructor(
        private sharedService: SharedService,
        private fileUploadService: FileUploadService,
        public api: ApiSchedulingService,
        private dialogRef: MatDialogRef<TemplateImportDialog>,
        protected woFlash: WoFlashService,
    ) { }

    ngOnInit(): void {
        combineLatest([
            this.sharedService.uploadedFile$,
            this.sharedService.selectedFileType$
        ]).subscribe(([file, fileType]) => {
            this.uploadedFile = file;
            this.selectedFileType = fileType;
            if (this.uploadedFile && this.selectedFileType)
                this.loadData();
        });
    }

    loadData() {
        this.loading = true;
        this.isLoading.emit(this.loading);
        this.errorFieldsChange.emit(this.errorFields = ['']);
        this.disableNext.emit(true);
        this.fileUploadService.upload(
            this.api.to('management/get-template-headers', { fileType: this.selectedFileType }), { file: this.uploadedFile }, { columnsToFormat: this.columnsToFormat }).then(res => {
                this.loading = false;
                this.disableNext.emit(false);
                this.rows = res['data']['importedFields'];
                this.iminFields = res['data']['iminFields']

                // Run handleMatSelectChange for each row
                this.rows.forEach(row => {
                    this.handleMatSelectChange(row.mappedField, row);
                });

                this.isLoading.emit(this.loading);
            }).catch(err => {
                this.errorFieldsChange.emit(this.errorFields = ['']);
                this.disableNext.emit(true);
                const msg = JSON.parse(err);
                this.dialogRef.close(msg);
            })


    }

    handleMatSelectChange(selectedValue: string | any, element: any) {
        // Check if the selected value is in iminFields
        const isValidSelection = this.iminFields.some(field => field['name'] === selectedValue);
        // If not valid, set the value to "skip"
        if (!isValidSelection) {
            element.mappedField = 'skip';
            element.isDuplicate = false;
        } else {
            // Check for duplicates only if the selected value is not "skip"
            const isDuplicate = this.rows.some(item => item !== element && item['mappedField'] === selectedValue && item['mappedField'] !== 'skip');
            element.isDuplicate = isDuplicate;
        }

        setTimeout(() => {
            this.updateErrorArray();
        }, 100);
    }

    updateErrorArray() {
        this.errorFields = [];

        this.mappedText = this.rows.length + " fields found and " + this.rows.filter(arr => arr['mappedField'] !== 'skip').length + " fields matched"
        // Check for unselected fields
        this.iminFields.forEach(field => {
            const isSelected = this.rows.some(item => item['mappedField'] === field['name'] && item['mappedField'] !== 'skip');
            if (!isSelected) {
                this.errorFields.push(field['label']);
            }
        });
        this.errorFieldsChange.emit(this.errorFields);

        const cmr = this.createMappingArray(this.rows, this.iminFields);
        this.mappedFieldsArray.emit(cmr);
    }

    createMappingArray(uploadedFields, iminFields) {
        const mappingArray = [];

        for (const iminField of iminFields) {
            const uploadedField = uploadedFields.find(field => field.mappedField === iminField.name);

            if (uploadedField) {
                mappingArray[iminField.fields] = uploadedField.label;
                // mappingArray[uploadedField.label] = iminField.fields;
            }
        }

        return mappingArray;
    }

}