<div class="c-page-not-found">
    <div class="c-page-form__header">
        <!-- <img src="/assets/pic/logo.svg" alt=""> -->
        <app-logo [light]="false"></app-logo>
    </div>
    <div class="c-page-not-found__content">
        <h2>Page unavailable</h2>
        <p>
            Your Imin site is currently unavailable.
        </p>
        <hr>
        <p>
            <b>Please check Client account for known problems</b>.
            If there are no known problems and your page hasn't appeared again in 5-10 minutes then <b>please contact our support team</b>.
        </p>
    <div>
</div>
