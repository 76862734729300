import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FileUploadService } from "@app/core/file-upload-service";
import { ApiAdminService } from "@app/share/api-admin.service";

export interface MatDialogData {
  fileToUpload: File,
}

@Component({
  selector: 'points-mat-dialog.component',
  templateUrl: 'points-mat-dialog.component.html',
})
export class PointsMatDialogComponent {
  button_ok: string = "Ok";
  progress: number = 0;
  loading: boolean = false;
  errors = [];

  constructor(
    public dialogRef: MatDialogRef<PointsMatDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatDialogData,
    public fileUploadService: FileUploadService,
    public api: ApiAdminService,
  ) { 
    const fileToUpload = this.data.fileToUpload;
    this.loading = true;
    console.log(fileToUpload)

    let uploadRef = this.fileUploadService.upload(this.api.to('group/import-points-template'), { file: fileToUpload }).then(res => {
      this.loading = false;

      // this.fileToUpload = null;
      console.log(res)
      if (res['errors'] && res['errors'].length > 0) {
        this.errors = res['errors'];
      } else {
        this.errors = [];
        // this.dialog.close(res);
      }
    }).catch(err => {
      this.loading = false;
    });

    this.fileUploadService.getProgress().subscribe(res => {
      this.progress = res;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}