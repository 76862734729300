import { Component, Renderer2, OnDestroy, HostBinding, ViewChild, AfterViewInit } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { WoFlashService } from '@app/wo-module/wo-flash/wo-flash.service';
import { StatisticsService } from './share/services/statistic.service';
import { ChatService } from '@app/share/chat.service';
import { MatExpansionPanel } from "@angular/material/expansion";

import { initializeApp } from "firebase/app";
// import { getMessaging, onBackgroundMessage } from "firebase/messaging/sw";

import { getMessaging, getToken } from "firebase/messaging";
import { ApiAdminService } from '@app/share/api-admin.service';


@Component({
    selector: 'app-admin-root',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.css'],
})
export class AdminComponent implements OnDestroy {
    public title;
    sideBarToggleStorageKey = '__sideBarToggleStorageKey';
    isAdmin = false;

    @HostBinding('class.sidebar-compact') isCompactSidebar: boolean;
    @HostBinding('class.no-logged') '!isLogged()';

    constructor(
        private render: Renderer2,
        public auth: AuthService,
        public router: Router,
        public woFlash: WoFlashService,
        public stats: StatisticsService,
        public chat: ChatService,
        public api: ApiAdminService
    ) {
        this.title = "Chime";
        this.render.addClass(document.body, 'admin-module');
        this.isCompactSidebar = !!parseInt(localStorage.getItem(this.sideBarToggleStorageKey), 10);
        this.entitiesInit();

        // const firebaseConfig = {
        //   apiKey: "AIzaSyCm9RPR_ne-8s-I3xgoxtH0wl-VUAF_dQk",
        //   authDomain: "chimein-e2346.firebaseapp.com",
        //   projectId: "chimein-e2346",
        //   storageBucket: "chimein-e2346.appspot.com",
        //   messagingSenderId: "1044962199679",
        //   appId: "1:1044962199679:web:97da7e056cef125cca574a",
        //   measurementId: "G-5CGK1Y0FP5"
        // };

        // const firebaseApp = initializeApp(firebaseConfig);
        // const messaging = getMessaging(firebaseApp);

        // getToken(messaging, {vapidKey: "BBsaeWd-os1VTK35os0rMQnyK9wSrCgYIbn5aJoX8gA8hq47LbOh21nQyRNQM9zTacc1FIr4nVydZ_jLmg_9gdE"}).then((currentToken) => {
        //     this.chat.init(currentToken);
        // }).catch((err) => {
        //     this.chat.init('');
        // });

        this.chat.init('');
        this.updatePermissions();
    }

    updatePermissions() {
        this.api.send("auth/permissions").then(res => {
            if (res["code"] == 200) {
                const user = this.auth.user;
                if (user['permissions']) {
                    user['permissions'] = res["permissions"];
                    this.auth.updatePermissions();
                    this.redirectByPermissions();
                }
            }
        })
    }

    redirectByPermissions(logo_url = false) {
        if (this.router.url == '/admin' || logo_url) {
            let current_url = '/admin/dashboard';
            const urlPermissions = [
                { url: '/admin/dashboard', can: this.auth.permissions["adminReportManagementAccess"] || this.auth.permissions["managerReportManagementAccess"]},
                { url: '/admin/timetable/workforce', can: this.auth.permissions["adminDynamicWorkforceManagementAccess"] || this.auth.permissions["managerDynamicWorkforceManagementAccess"]},
                { url: '/admin/chat', can: this.auth.permissions["adminChatManagementAccess"] || this.auth.permissions["managerChatManagementAccess"]},
                { url: '/admin/users', can: this.auth.permissions["adminUserManagementAccess"] || this.auth.permissions["managerUserManagementAccess"]},
                { url: '/admin/groups', can: this.auth.permissions["adminLobManagementAccess"] || this.auth.permissions["managerLobManagementAccess"]},
                { url: '/admin/clients', can: this.auth.can('globalAdmin')},
                { url: '/admin/help-center', can: true},
            ]
            for (const urlPermission of urlPermissions) {
                if (urlPermission.can) {
                    current_url = urlPermission.url;
                    break;
                }
            }
            this.router.navigate([current_url]);
        }
    }

    entitiesInit() {
        this.router.events.subscribe((evt) => {
            if (evt instanceof NavigationEnd) {
                if (this.auth.can("admin") || this.auth.can("manager")) {
                    this.stats.updateEntities();
                }
            }
        });

        this.stats.updateEntities();
    }

    isLogged() {
        return !this.auth.isGuest;
    }

    logOut() {
        this.auth.logout();
        this.router.navigate(['/login']);
    }

    sidebarToggle() {
        this.isCompactSidebar = !parseInt(localStorage.getItem(this.sideBarToggleStorageKey), 10)
        localStorage.setItem(this.sideBarToggleStorageKey, this.isCompactSidebar ? '1' : '0');
    }

    showUsersMenu() {
        return true;
    }

    ngOnDestroy() {
        this.render.removeClass(document.body, 'admin-module');
    }

    toRoute(path) {
        this.router.navigate(["/admin/" + path]);
    }
}
