import { Injectable } from '@angular/core';
import { HttpService } from '../core/http.service';
import { environment } from '../../environments/environment';
import { ApiAdminService } from './api-admin.service';
import { Observable, Subscription, timer } from 'rxjs';
import * as moment from 'moment';

export const TIMER_UPDATE_DELAY = 5000;

@Injectable()
export class TimeZonesService {
    private static timezones: object = null;

    private static _currentTime = new Date();
    private static timerSource: Observable<number>;
    private static timerSubscription$: Subscription;

    constructor(
        private api: ApiAdminService
    ) {
        TimeZonesService.startTimer();
    }

    public async getTimeZones() {
        if (TimeZonesService.timezones == null) {
            const res = await this.api.send('timezones/all');
            if (res['data']) {
                TimeZonesService.timezones = res['data'] as object;
            }
        }
        return await TimeZonesService.timezones;
    }

    private static startTimer() {
        if (TimeZonesService.timerSource == null) {
            TimeZonesService.timerSource = timer(TIMER_UPDATE_DELAY, TIMER_UPDATE_DELAY);
        }
        if (TimeZonesService.timerSubscription$ == null) {
            TimeZonesService.timerSubscription$ = TimeZonesService.timerSource.subscribe(iteration => TimeZonesService._currentTime = new Date());
        }
    }

    private static endTimer(): void {
        if (this.timerSubscription$ != null) {
            this.timerSubscription$.unsubscribe();
            this.timerSubscription$ = this.timerSource = null;
        }
    }

    public getCurrentTimeTimezoned(timezone : string, format : string = 'h:mm A') : {
        curTimeFmt: string,
        curMinutes: number,
    } {
        const curTime = moment.tz(TimeZonesService._currentTime, timezone);
        const curTimeFmt = curTime.format(format);
        const curMinutes = Math.floor((curTime.unix() - curTime.startOf('day').unix()) / 60);
        return {
            curTimeFmt,
            curMinutes
        }
    }
}
