<form class="report__create-form" [formGroup]="reportForm">
    <div class="report__row" style="margin: 15px 0">
        <app-mat-select-search appearance="fill" [data]="groups" propToCompare="title" propValue="id"
            label="Line of Business" [(val)]="active_group" (valChange)="loadStat()" ngDefaultControl>
        </app-mat-select-search>
        <client-code-panel></client-code-panel>
    </div>

    <section class="chart__section">
        <mat-card class="chart__container">
            <mat-tab-group (selectedIndexChange)="onModChanged($event)">
                <mat-tab label="Daily overview"></mat-tab>
                <mat-tab label="Current week"></mat-tab>
                <mat-tab label="Previous week"></mat-tab>
                <mat-tab label="Current month"></mat-tab>
                <mat-tab label="Previous month"></mat-tab>
            </mat-tab-group>
            <canvas [ngClass]="{'loading': statLoading}" class="chart" #canvas></canvas>
            <mat-spinner *ngIf="statLoading" diameter="42"></mat-spinner>
        </mat-card>
        <section class="sub__container">
            <mat-card class="stat__card">
                <mat-card-title>
                    Overall attendance
                </mat-card-title>
                <mat-card-content class="stat__container">
                    <section class="stat">
                        <article class="stat_element">
                            <span>Fullfilment</span>
                            <ng-container *ngIf="!statLoading; else totalLoading">
                                <strong>{{total.total_taken_percent}}%</strong>
                            </ng-container>
                            <ng-template #totalLoading>
                                <mat-spinner diameter="24"></mat-spinner>
                            </ng-template>
                            
                            <mat-progress-bar class="taken" mode="determinate" value="{{total.total_taken_percent}}"></mat-progress-bar>
                            <small>fullfilled</small>
                        </article>
                        <article class="stat_element">
                            <span>Cancellation</span>
                            <ng-container *ngIf="!statLoading; else canceledLoading">
                                <strong>{{total.total_canceled_percent}}%</strong>
                            </ng-container>
                            <ng-template #canceledLoading>
                                <mat-spinner class="canceled" diameter="24"></mat-spinner>
                            </ng-template>
                            <mat-progress-bar class="canceled" mode="determinate" value="{{total.total_canceled_percent}}"></mat-progress-bar>
                            <small>cancelled</small>
                        </article>
                    </section>
                </mat-card-content>
            </mat-card>
            <mat-card class="report__card">
                <mat-card-title>
                    Download Report
                </mat-card-title>
                <mat-card-content class="report">
                    <mat-form-field appearance="outline" class="reports__type">
                        <mat-label>Report Type</mat-label>
                        <mat-select name="report" formControlName="type">
                            <mat-option *ngFor="let report of REPORTS | keyvalue" [value]="report.key">
                                {{report.value}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="reportForm.controls['type'].touched && reportForm.get('type').invalid"
                            class="text-invalid">
                            {{getFirstFormError(reportForm, 'type')}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="report__date_range">
                        <mat-label>Enter a Report Date Range</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                            <input matStartDate placeholder="Start Report Date" formControlName="start_time">
                            <input matEndDate placeholder="End Report Date" formControlName="end_time">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                        <mat-error *ngIf="reportForm.controls['start_time'].touched && reportForm.get('start_time').invalid"
                            class="text-invalid">
                            {{getFirstFormError(reportForm, 'start_time')}}
                        </mat-error>
                        <mat-error
                            *ngIf="reportForm.get('start_time').valid && reportForm.controls['end_time'].touched && reportForm.get('end_time').invalid"
                            class="text-invalid">
                            {{getFirstFormError(reportForm, 'end_time')}}
                        </mat-error>
                    </mat-form-field>
                    <a [href]="reportFileLink" mat-stroked-button color="primary" class="reports__download-btn"
                        (click)="download($event)">
                        Download Report
                        <mat-icon>sim_card_download</mat-icon>
                    </a>
                </mat-card-content>
            </mat-card>
        </section>
    </section>
</form>