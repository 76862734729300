import { Injectable } from '@angular/core';
import { HttpService } from '../core/http.service';
import { environment } from '../../environments/environment';
import IApi from './IApi';

@Injectable()
export class ApiMobileService implements IApi {
    constructor(private http: HttpService) {}

    send(url, get = null, post = null) {
        return this.http.send(environment.API_BASE_URL + '/mobile/v1/' + url, get, post);
    }

    to: (url: any, get_params: any, use_token: any) => '';
}
