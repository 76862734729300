import { AfterContentInit, AfterViewInit, Component, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { WoBreadCrumbsService } from '@app/wo-module/wo-breadcrumbs/wo-breadcrumbs.service';
import { MetaPageService } from '@app/core/meta-page.service';
import { AuthService } from '@app/auth/auth.service';
import { ApiAdminService } from '@app/share/api-admin.service';

@Component({
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {
    constructor(
        public breadcrumbs: WoBreadCrumbsService,
        public metaPage: MetaPageService,
        public auth: AuthService,
        public api: ApiAdminService
    ) {
        this.breadcrumbs.setLinks([
            { iconClass: 'fa fa-tachometer', route: '/admin', text: 'Home' },
            { text: 'Dashboard' }
        ]);
        this.metaPage.setTitle('Dashboard');
    }

    get canDownload() {
        return this.auth.permissions["adminReportManagementAccess"] || this.auth.permissions["managerReportManagementAccess"];
    }
}
