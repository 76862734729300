import { Injectable } from '@angular/core';
import { HttpService } from '../core/http.service';
import { environment } from '../../environments/environment';
import IApi from './IApi';

@Injectable()
export class ApiAdminService implements IApi {
    constructor(private http: HttpService) {}

    get baseUrl() {
        return environment.API_BASE_URL  + '/admin';
    }

    /**
     * @deprecated The method should not be used
    */
    send(url, get = null, post = null) {
        return this.http.send(this.baseUrl + '/' + url, get, post);
    }

    get (url: string, get = null) {
        return this.http.exec("GET", this.baseUrl +  "/" + url, get);
    }

    post (url: string, get = null, post_params = null) {
        return this.http.exec("POST", this.baseUrl +  "/" + url, get, post_params);
    }

    to (url, get_params = {}, use_token = true) {
        return this.http.to(this.baseUrl + '/' + url, get_params, use_token);
    }
}
