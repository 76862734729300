import { Component, OnInit} from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { UserImgServices } from '../../services/user-img.services';
import { ApiAdminService } from '@app/share/api-admin.service';

@Component({
  selector: 'app-user-img-modal',
  templateUrl: './user-img-modal.component.html',
  styleUrls: ['./user-img-modal.component.css']
})
export class UserImgModalComponent implements OnInit {
  
  public file;
  croppedImage: any = '';

  constructor(
    public userImgServices: UserImgServices,
    public api: ApiAdminService
  ) {
  }

  ngOnInit(): void {
    this.file = this.userImgServices.getFile();
  }
 
  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
  }
  
  saveImg() {
        this.api.send('user/avatar', {}, {'img': this.croppedImage}).then(res => {
          if(res['code'] == 200) {
              this.userImgServices.setSharedData(res['url']);
          }
        });
  }
}
