<div class="c-login-form__wrapper">
    <div class="c-login-form">
        <div class="c-login-form__title">
            <app-logo [light]="false"></app-logo>
        </div>

        <div class="c-login-form__title">
            Reset Password
        </div>

        <wo-flash></wo-flash>

        <div class="c-login-form__content">
            <ng-container [ngSwitch]="isValidToken">
                <ng-container *ngSwitchCase="null">
                    Loading...
                </ng-container>
                <ng-container *ngSwitchCase="false">
                    Wrong token was provided. Unable to set password.
                </ng-container>
                <ng-container *ngSwitchCase="true">
                    <div *ngIf="isLoading" class="slider__wrapper">
                        <div class="slider">
                          <div class="line"></div>
                          <div class="break dot1"></div>
                          <div class="break dot2"></div>
                          <div class="break dot3"></div>
                        </div>
                    </div>

                    <div class="c-login-form__note">
                        Please set your password below.
                        <!-- Password must be at least 10 characters long and contain at least one of each: uppercase, lowercase letter, digit, special chatacter. -->
                    </div>

                    <form action="javascript:;" (submit)="doSend()">
                        <div class="b-form-row">
                            <div class="b-form-row__field">
                                <div class="b-form-row__field-icon">
                                    <i class="fa fa-key" aria-hidden="true"></i>
                                    <input class="form-control" [ngClass]="{'error': formErrors?.password }" placeholder="Password" [(ngModel)]="password" type="password" name="password">
                                </div>

                                <div [ngClass]="{show: formErrors?.password}" class="label-error">
                                    {{formErrors?.password}}
                                </div>
                            </div>
                        </div>
                        <div class="b-form-row">
                            <div class="b-form-row__field">
                                <div class="b-form-row__field-icon">
                                    <i class="fa fa-key" aria-hidden="true"></i>
                                    <input class="form-control" [ngClass]="{'error': formErrors?.password_repeat }" placeholder="Password Repeat" [(ngModel)]="password_repeat" type="password" name="password_repeat">
                                </div>

                                <div [ngClass]="{show: formErrors?.password_repeat}" class="label-error">
                                    {{formErrors?.password_repeat}}
                                </div>
                            </div>
                        </div>

                        <div class="form-controls">
                            <a routerLink="/admin/login">Back to login</a>
                            <input [ngClass]="{'btn-loading':isLoading}" type="submit" value="Submit">
                        </div>
                    </form>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>