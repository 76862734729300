<div class="schedule-patterns__uploader">
    <ng-container *ngIf="fileName == null; else showFile">
        <label *ngIf="!isLoading" class="schedule-patterns__uploader-inner">
            <input placeholder=" " (change)="onFileChange($event)" type="file" name="file" [accept]="acceptedFileTypes">
            <mat-icon>upload</mat-icon>
            <label>Choose a file or drag it here</label>
            <span class="err_msg" *ngIf="err_msg != null">{{err_msg}}</span>
        </label>
    </ng-container>
    <ng-template #showFile>
        <label class="schedule-patterns__uploader-inner">
            <mat-icon>insert_drive_file</mat-icon>
            <label>
                {{fileName}}
                <button class="close_btn" (click)="removeFile($event)"><mat-icon>close</mat-icon></button>
            </label>
        </label>
    </ng-template>
    <div *ngIf="isLoading" class="schedule-patterns__uploader-loading">
        <mat-spinner diameter="50"></mat-spinner>
    </div>
</div>