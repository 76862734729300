import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiAdminService } from '@app/share/api-admin.service';
import { ResizeEvent } from 'angular-resizable-element';

export interface changeGroupEvent {
  group_title: string,
  group_id: number,
  isInit?: boolean,
  timezone: string,
  timezone_name: string,
  is_static_group_enabled: boolean;
  is_cstm_templte_enabled: boolean;
}

export interface group {
  title: string,
  id: number,
  timezone: string,
  timezone_name: string,
}

@Component({
  selector: 'app-lob-picker',
  templateUrl: './lob-picker.component.html',
  styleUrls: ['./lob-picker.component.scss']
})
export class LobPickerComponent implements OnInit {
  search = '';
  active_group = null;
  company_id = null;
  groups = [];
  filteredGroups = [];
  companies = [];
  displayedColumns = ['group', 'company'];
  companiesById = {};
  selectedGroupId = 0;
  @Input() startWidth: number = 400;
  @Input() maxWidth: number = 600;
  @Input() minWidth: number = 380;
  currentWidth: number = 600;
  @Input() resizable: boolean = false;
  @Output() changeGroup = new EventEmitter<changeGroupEvent>();
  compact: boolean = false;

  resizing: boolean = false;


  constructor(
    protected api: ApiAdminService,
  ) { }

  ngOnInit(): void {
    this.loadGroups();
    this.loadCompanies();
    if (this.resizable) {
      this.currentWidth = this.startWidth;
    }
  }

  loadGroups() {
    this.api.send('group/all-groups', {}, {}).then(res => {
      this.groups = res['data'];
      this.filteredGroups = [...this.groups];
      if (this.groups[0]) {
        this.doSelectGroup(this.groups[0]['id'], true);
      }
    });
  }

  loadCompanies() {
    this.api.send('company/all-companies').then(res => {
      this.companies = res['data'];
      this.companies.forEach((company, index) => {
        this.companiesById[company['id']] = company;
      });
    });
  }

  onSearch() {
    // By Group
    let show_group_by_group = null;
    if (this.active_group > 0) {
      show_group_by_group = [];
      show_group_by_group.push(this.active_group);

      this.groups.forEach(group => {
        if (group.parent_id == this.active_group) {
          show_group_by_group.push(group.id);
        }
      });
    }

    // By Search
    let show_group_by_search = null;
    var searchVal = this.search.trim().toLowerCase();
    if (searchVal !== '') {
      show_group_by_search = [];

      this.groups.forEach(group => {
        if (group.title.toLowerCase().includes(searchVal)) {
          show_group_by_search.push(group.id);
          show_group_by_search.push(group.parent_id);
        }
      });
    }

    // By Company id
    let show_group_by_company = null;
    if (this.company_id > 0) {
      show_group_by_company = [];
      this.groups.forEach(group => {
        if (this.company_id == group.company_id) {
          show_group_by_company.push(group.id);
          show_group_by_company.push(group.parent_id);
        }
      });
    }


    if (show_group_by_group !== null || show_group_by_search !== null || show_group_by_company !== null) {
      this.filteredGroups = [];
      var showOnlyGroupIds = null;

      if (show_group_by_group != null) {
        showOnlyGroupIds = show_group_by_group;
      }

      if (show_group_by_search != null) {
        if (showOnlyGroupIds === null) {
          showOnlyGroupIds = show_group_by_search;
        } else {
          showOnlyGroupIds = showOnlyGroupIds.filter(x => show_group_by_search.includes(x));
        }
      }

      if (show_group_by_company != null) {
        if (showOnlyGroupIds === null) {
          showOnlyGroupIds = show_group_by_company;
        } else {
          showOnlyGroupIds = showOnlyGroupIds.filter(x => show_group_by_company.includes(x));
        }
      }

      this.groups.forEach(group => {
        if (showOnlyGroupIds.indexOf(group.id) !== -1) {
          this.filteredGroups.push(group);
        }
      });
    }
  }

  resetFilter(event) {
    this.active_group = null;
    this.search = '';
    this.company_id = null;
    this.filteredGroups = [...this.groups];
  }

  doSelectGroup(group_id, isInit = false) {
    const group = this.groups.find(group => group['id'] == group_id);
    if (!group) {
      throw new Error(`Can't find group with id ${group_id}`);
    }

    this.selectedGroupId = group_id;

    this.changeGroup.emit({
      group_id,
      isInit: isInit,
      group_title: group['title'],
      timezone: group['timezone'],
      timezone_name: group['timezone_name'],
      is_static_group_enabled: group['is_sub_groups_enabled'] == "1" ? true : false,
      is_cstm_templte_enabled: group['is_cstm_templte_enabled'] == "1" ? true : false
    });
  }

  resize(event: ResizeEvent): void {
    let resultValue = this.startWidth;
    if (event.rectangle.width) {
      if (event.rectangle.width > this.maxWidth) {
        resultValue = this.maxWidth;
      } else if (event.rectangle.width < 50) {
        resultValue = 0;
        this.compact = false;
        this.toggleCompact();
      } else if (event.rectangle.width < this.minWidth) {
        resultValue = this.minWidth;
        this.compact = true;
        this.toggleCompact();
      } else {
        resultValue = event.rectangle.width;
      }
    }
    this.currentWidth = resultValue;
  }

  onMouseDown(event) {
    console.log(event);
  }

  toggleCompact() {
    this.compact = !this.compact;
    if (!this.compact) {
      this.currentWidth = this.minWidth;
    } else {
      this.currentWidth = 0;
    }
  }
}
