import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import { Router } from '@angular/router';
import { ChatService } from '@app/share/chat.service';
import { UserImgServices } from '../services/user-img.services';

@Component({
    selector: 'app-user-menu',
    templateUrl: 'user-menu.component.html',
    styleUrls: ['user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
    public authService;
    public isOpen = false;
    public userId = 0;
    public role: 'admin' | 'public' | 'manager' = 'admin';
    public imgAvatar = '';

    constructor (
        private auth: AuthService,
        private router: Router,
        private chat: ChatService,
        public  userImgServices: UserImgServices,
    ) {
        this.authService = this.auth;
        this.userId = this.auth.user['id'];
        if (this.auth.user['roles']['admin'] || this.auth.user['roles']['superAdmin']) {
            this.role = 'admin';
        } else {
            this.role = 'manager';
        }

        document.addEventListener('mouseup', (event) => {
            const toggleState = this.isOpen;
            setTimeout(() => {
                if (toggleState === true) {
                    this.isOpen = false;
                }
            }, 1);
        });
    }

    ngOnInit(): void{
        this.userImgServices.sharedData$.subscribe(function(data) {
            this.imgAvatar = data
        }.bind(this));
        this.imgAvatar = this.authService.user.avatar;
    }

    toggleMenu () {
        this.isOpen = !this.isOpen;
    }

    myProfile() {
        this.router.navigate(['/admin/' + this.role + "s" + '/my-profile']);
    }

    logOut() {
        this.chat.logout();
        this.authService.logout();
        this.router.navigate(['/admin/login']);
    }
}
