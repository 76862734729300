import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { CoreModule } from './core/core.module';
import { WoModule } from './wo-module/wo.module';
import { WoPaginatorService } from './wo-module/wo-paginator/wo-paginator.service';
import { ShareModule } from './share/share.module';
import { ShareModule as OtherShareModule } from './admin/share/share.module';
import { ModalModule } from '@app/modal/modal.module';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';

import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './share/page-not-found/page-not-found.component';

import { AuthService } from './auth/auth.service';
import { AuthAdminGuard } from './auth/auth-admin-guard.service';
import { AuthGuestGuard } from './auth/auth-guest-guard.service';
import { AuthManagerGuard } from './auth/auth-manager-guard.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthStaffGuard } from './auth/auth-staff-guard.service';
import { AuthPermissionGuard } from './auth/auth-permission-guard.service';
import { AuthGlobalAdminGuard } from './auth/auth-global-admin-guard.service';
import { DomainNotFoundComponent } from './share/domain-not-found/domain-not-found.component';
import { AuthInstanceGuard } from './auth/auth-instance-guard.service';

const appRoutes: Routes = [
    {
        path: '', canActivateChild: [AuthInstanceGuard], children: [
            { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
            { path: 'scheduling', loadChildren: () => import('./scheduling/scheduling.module').then(m => m.SchedulingModule), },
            { path: 'general', loadChildren: () => import('./general/general.module').then(m => m.GeneralModule), },
            { path: 'help', loadChildren: () => import('./help/help.module').then(m => m.HelpModule), },
            { path: '', redirectTo: 'admin', pathMatch: 'full' },
            { path: 'mobile', loadChildren: () => import('./mobile/mobile.module').then(m => m.MobileModule) },
            { path: 'wrong-client', component: DomainNotFoundComponent },
            { path: '**', component: PageNotFoundComponent },
        ]
    }];

@NgModule({
    declarations: [
        AppComponent,
        PageNotFoundComponent,
        DomainNotFoundComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
        CoreModule,
        WoModule,
        ModalModule,
        ShareModule,
        BrowserAnimationsModule,
        OtherShareModule
    ],
    providers: [
        AuthAdminGuard,
        AuthGuestGuard,
        AuthManagerGuard,
        AuthPermissionGuard,
        AuthStaffGuard,
        AuthInstanceGuard,
        AuthGlobalAdminGuard,
        AuthService,
        Title,
        PageEvent,
        {
            provide: MatPaginatorIntl,
            useClass: WoPaginatorService
        }
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
