import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SharedService {
    private uploadedFileSubject = new BehaviorSubject<File | null>(null);
    uploadedFile$ = this.uploadedFileSubject.asObservable();

    private selectedFileTypeSubject = new BehaviorSubject<string | null>(null);
    selectedFileType$ = this.selectedFileTypeSubject.asObservable();

    setUploadedFile(file: File): void {
        this.uploadedFileSubject.next(file);
    }

    setSelectedFileType(fileType: string): void {
        this.selectedFileTypeSubject.next(fileType);
    }
}