import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HttpService } from '@app/core/http.service';
import { AuthService } from '@app/auth/auth.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ChatService } from '@app/share/chat.service';

@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.css']
})

export class LoginComponent {
    username = '';
    password = '';
    formErrors: any;
    isLoading = false;

    constructor(
        private title: Title,
        private http: HttpService,
        private auth: AuthService,
        private router: Router,
        private chat: ChatService,
    ) {
        this.title.setTitle('Login');
        if (this.isLoading && (this.auth.can('admin') || this.auth.can('manager') || this.auth.can('superAdmin'))) {
            this.router.navigate(['/admin']);
        }
    }

    doLogin() {
        if (this.isLoading === true) {
            return false;
        }
        this.isLoading = true;
        this.formErrors = {};
        this.http.send(
            environment.API_BASE_URL + '/admin/auth/token',
            null,
            { username: this.username, password: this.password }
        ).then((res) => {
            this.isLoading = false;
            if (!res['errors']) {
                this.auth.setToken(res['access_token'], res['expires_in']);
                this.auth.setRefreshToken(res['refresh_token']);
                this.auth.setUser(res['user']);
                this.redirectAfterLogin()
                this.chat.login();
            } else {
                setTimeout(() => {
                    this.formErrors = res['errors'];
                }, 200);
            }
        }).catch(err => {
            console.error(err);
            this.formErrors = {};
            this.isLoading = false;
            alert('Oops! Something went wrong.');
        });
    }

    redirectAfterLogin() {
        let current_url = '/admin/dashboard';
        const urlPermissions = [
            { url: '/scheduling/dashboard', can: this.auth.can('analyst') || this.auth.can('wfm_manager') || this.auth.can('supervisor')},
            { url: '/admin/dashboard', can: this.auth.permissions["adminReportManagementAccess"] || this.auth.permissions["managerReportManagementAccess"] },
            { url: '/admin/timetable/workforce', can: this.auth.permissions["adminDynamicWorkforceManagementAccess"] || this.auth.permissions["managerDynamicWorkforceManagementAccess"] },
            { url: '/admin/chat', can: this.auth.permissions["adminChatManagementAccess"] || this.auth.permissions["managerChatManagementAccess"] },
            { url: '/admin/users', can: this.auth.permissions["adminUserManagementAccess"] || this.auth.permissions["managerUserManagementAccess"] },
            { url: '/admin/groups', can: this.auth.permissions["adminLobManagementAccess"] || this.auth.permissions["managerLobManagementAccess"] },
            { url: '/admin/clients', can: this.auth.can('globalAdmin')},
            { url: '/admin/help-center', can: true },
        ];

        for (const urlPermission of urlPermissions) {
            if (urlPermission.can) {
                current_url = urlPermission.url;
                break;
            }
        }

        this.router.navigate([current_url]);
    }
}
