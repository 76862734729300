<div class="avatar-content">
    <div class="avatar-content__icon">
        <div class="avatar-content__icon-plug" (click)="upload()" matTooltip="Upload photo" *ngIf="!url">
            <mat-icon>add_a_photo</mat-icon>
        </div>
        <div class="avatar-content__icon-avatar" *ngIf="url">
            <img [src]="url" alt="avatar">
        </div>
    </div>
    <input class="file_input" #file type="file" accept="image/*"  (change)="fileChangeEvent($event)" >
    <div class="avatar-content__button" *ngIf="url">  
        <button mat-icon-button matTooltip="Update photo" color="primary" (click)="upload()">
            <mat-icon>add_a_photo</mat-icon>
        </button>
        <button mat-icon-button matTooltip="Delete photo" color="warn" (click)="delete()">
            <mat-icon>delete_outline</mat-icon>
        </button>
    </div>
</div>
