import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { SharedService } from '../shared.service';

@Component({
    selector: 'app-upload-file',
    templateUrl: './upload-file.component.html',
    styleUrls: ['./upload-file.component.scss']
})

export class UploadFileComponent implements OnInit {
    @Output() fileUploaded = new EventEmitter<boolean>();
    @Output() disableNext = new EventEmitter<boolean>(false);
    acceptedFileTypes: string = '';

    isLoading: boolean = true;
    fileName: string = null;
    err_msg: string = null;
    selectedFileType = null;

    constructor(
        private sharedService: SharedService
    ) { }

    ngOnInit() {
        this.isLoading = false;

        // Subscribe to changes in selected file type
        this.sharedService.selectedFileType$.subscribe(fileType => {
            this.onFileTypeSelected(fileType);
        });
    }

    onFileChange(event: any): void {
        this.disableNext.emit(false);
        const file: File = event.target.files[0];
        const fileExtension = file.name.split('.').pop();
        const excel_extensions = ['xls', 'xlsx', 'csv'];
        // console.log(this.selectedFileType, fileExtension);
        if (this.selectedFileType == 'excel' && !excel_extensions.includes(fileExtension)) {
            this.err_msg = "The selected file format is not correct";
        } else if (this.selectedFileType == "csv" && fileExtension != "csv") {
            this.err_msg = "The selected file format is not correct";
        } else {
            if (file) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    this.fileName = file.name;
                    this.fileUploaded.emit(true);
                };

                reader.readAsDataURL(file);

                this.sharedService.setUploadedFile(file);
            }
        }
    }


    removeFile(event) {
        this.fileName = null;
        this.err_msg = null;
        event.preventDefault();
        this.disableNext.emit(true);
    }

    onFileTypeSelected(selectedType: string): void {
        this.selectedFileType = selectedType;
        // Set accepted file types based on user input or dynamic condition
        if (this.selectedFileType === 'csv') {
            this.acceptedFileTypes = '.csv';
        } else if (this.selectedFileType === 'excel') {
            this.acceptedFileTypes = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        }
    }
}