<div class="container">
  <h1 class="title" mat-dialog-title>{{title}}</h1>
  <ng-container *ngIf="!loading; else loadingPart">
    <button class="cross" mat-icon-button [mat-dialog-close]="false"><mat-icon>close</mat-icon></button>
  </ng-container>
  <ng-template #loadingPart>
    <mat-spinner diameter="40" class="cross"></mat-spinner>
  </ng-template>
  
  <ng-container *ngIf="!loadingClient; else loadingClientTemplate">
    <form [formGroup]="form">
      <app-mat-select-search appearance="outline" [data]="clients" propToCompare="title" propValue="id" name="client"
          [formControl]="form.get('client_id')" label="Client">
      </app-mat-select-search>
      <mat-checkbox formControlName="export_all">Export All</mat-checkbox>
      <mat-form-field *ngIf="!form.get('export_all')?.value">
        <mat-label>Created Date</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate name="date_from" formControlName="date_from" placeholder="Start Date">
          <input matEndDate name="date_to" formControlName="date_to" placeholder="End Date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </form>
  </ng-container>
  <ng-template #loadingClientTemplate>
    <mat-spinner diameter="24"></mat-spinner>
  </ng-template>
  <!-- <div mat-dialog-content class="description">{{description}}</div> -->
  <div mat-dialog-actions class="actions" style="margin-bottom: 0;">
    <button mat-raised-button color="primary" [mat-dialog-close]="false">Close</button>
    <a mat-raised-button color="primary" [href]="exportUrl" [disabled]="form.get('client_id')?.value == null">Export</a>
  </div>
</div>