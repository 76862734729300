import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { ApiMobileService } from "@app/share/api-mobile.service";

export interface MatDialogData {
  title: string,
  description: string,
  button_yes: string,
  button_no: string,
}

@Component({
  selector: 'email-change-dialog.component',
  templateUrl: 'email-change-dialog.component.html',
})
export class EmailChangeDialogComponent {
  title: string = "";
  description: string = "";
  button_yes: string = "Yes";
  button_no: string = "No";
  status : "loading" | "confirmed" | "error" = "loading";
  newEmail : string = "";

  constructor(
    public dialogRef: MatDialogRef<EmailChangeDialogComponent>,
    public activeRoute: ActivatedRoute,
    public api: ApiMobileService,
    @Inject(MAT_DIALOG_DATA) public data: MatDialogData
  ) { 
    this.description = "You're about to change email to ";
    this.title = "Email Changing";
    this.status = 'loading';
    this.activeRoute.queryParams.subscribe(params => {
      this.api.send("user/change-email", { code: params["code"] }, {}).then(res => {
        if (res["code"] == 200) {
          this.status = 'confirmed';
          this.newEmail = res["newEmail"];
        } else {
          this.status = 'error';
        }
      }).catch(() => this.status = 'error');
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}