<div class="container">
  <button class="cross" mat-icon-button [mat-dialog-close]="true"><mat-icon>close</mat-icon></button>
  <mat-icon style="font-size: 50px; width: 50px; height: 50px; color: #f44336; margin-bottom: 5px;">warning</mat-icon>
  <div mat-dialog-content>
    <div class="description" *ngFor="let error of errors" >{{error}}</div>
  </div>
  
  <div mat-dialog-actions class="actions">
    <button mat-raised-button color="primary" [mat-dialog-close]="true">OK</button>
  </div>
</div>