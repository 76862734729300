<section class="panel" [ngStyle]="{'background-color': bgColor}">
    <button *ngIf="closable" (click)="toggleClosed()" mat-icon-button>
        <ng-container *ngIf="!closed; else openTemplate">
            <mat-icon>chevron_right</mat-icon>
        </ng-container>
        <ng-template #openTemplate>
            <mat-icon>schedule</mat-icon>
        </ng-template>
    </button>
    <div class="row" [@openClose]="closed ? 'closed' : 'open'">
        <article class="clock" *ngFor="let timezone of timezones">
            <analog-watch [momentEntry]="getTimezonedDate(timezone.key)"></analog-watch>
            <span>{{timezone.title}}</span>
        </article>
    </div>
</section>