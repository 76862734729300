<div class="container">
  <div class="title">
    <h1 mat-dialog-title>Transfer all data</h1>
    <button class="cross" mat-icon-button [mat-dialog-close]="false">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content class="description">
    <ng-container *ngIf="!loading; else loadingTemplate">
      <mat-accordion *ngIf="data != null">
        <mat-expansion-panel
          (opened)="usersOpen = true"
          (closed)="usersOpen = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title> Users </mat-panel-title>
            <mat-panel-description>
              These users will be created or edited due to old version
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-list role="list" *ngIf="usersOpen">
            <mat-list-item *ngFor="let user of data.users" role="listitem">
              {{ user.email }}
              <small>{{ user.first_name }} {{ user.last_name }}</small>
            </mat-list-item>
          </mat-list>
        </mat-expansion-panel>
        <mat-expansion-panel
          (opened)="groupsOpen = true"
          (closed)="groupsOpen = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title> Lobs </mat-panel-title>
            <mat-panel-description>
              These lobs will be created or edited due to old version
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-list role="list" *ngIf="groupsOpen">
            <ng-container *ngFor="let group of data.groups" role="listitem">
              <mat-list-item *ngIf="group.status != 'deleted'" role="listitem">
                <ng-container>
                  {{ group.title }} <small>{{ group.timezone }}</small>
                </ng-container>
              </mat-list-item>
            </ng-container>
          </mat-list>
        </mat-expansion-panel>
        <mat-expansion-panel
          (opened)="userShiftsOpen = true"
          (closed)="userShiftsOpen = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title> Shifts </mat-panel-title>
            <mat-panel-description>
              These shifts will be created or edited due to old version
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-list role="list" *ngIf="userShiftsOpen">
            <mat-list-item
              *ngFor="let shift of data.userShifts"
              role="listitem"
            >
              {{ shift.start_datetime }} - {{ shift.end_datetime }}
              <small
                >{{ shift.email }} {{ shift.first_name }}
                {{ shift.last_name }}</small
              >
            </mat-list-item>
          </mat-list>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>
    <ng-template #loadingTemplate>
      <mat-spinner diameter="24"></mat-spinner>
    </ng-template>
  </div>
  <div mat-dialog-actions class="actions">
    <button mat-raised-button color="primary" [mat-dialog-close]="false">
      Cancel
    </button>
    <button
      [disabled]="loading"
      mat-raised-button
      color="primary"
      (click)="uploadAllData()"
    >
      Transfer
    </button>
  </div>
</div>
