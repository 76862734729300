import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[dnd]'
})
export class DndDirective {
  @HostBinding('class.fileover') fileover: boolean;
  @Output() fileDropped = new EventEmitter();
  files: any[] = [];

  constructor() {}

  onFileDropped($event) {
    for (const item of $event) {
      this.files.push(item);
    }
  } 

  @HostListener('dragover', ['$event']) onDragOver(evt : Event) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileover = true;
  }

  @HostListener('dragleave', ['$event']) onDragLeave(evt : Event) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileover = false;
  }

  @HostListener('drop', ['$event']) onDrop(evt : Event) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileover = false;
    const files = evt['dataTransfer'].files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }
}
