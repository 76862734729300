import { Component, Input, OnInit } from '@angular/core';
import { ApiMemberService } from '@app/share/api-member.service';
import { LogoService } from '@app/share/logo.service';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {

  constructor(
    public api: ApiMemberService,
    public logoService: LogoService,
  ) { }

  ngOnInit(): void { }

  @Input()
  light : boolean = true;

  @Input()
  innerStyle : string = ""; 
}
