<article>
    <span>ID</span>
    <button [disabled]="authService.clientCode == null" matTooltip="Click to copy" type="button" mat-flat-button [cdkCopyToClipboard]="authService.clientCode">
        <ng-container *ngIf="authService.clientCode != null;else loading" >
            {{authService.clientCode | mask: '000 - 000'}}
        </ng-container>
        <ng-template #loading>
            <mat-spinner diameter="24"></mat-spinner>
        </ng-template>
    </button>
</article>