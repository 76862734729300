import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'day-picker',
  templateUrl: './day-picker.component.html',
  styleUrls: ['./day-picker.component.scss']
})
export class DayPickerComponent implements OnInit {
  @Output()
  onDayChange : EventEmitter<String> = new EventEmitter();

  @Input()
  startDate : moment.Moment;
  @Input()
  appearance : String;

  date : UntypedFormControl = new UntypedFormControl();

  constructor() {
    this.date.valueChanges.subscribe(() => {
      this.emitDay();
    });
  }

  ngOnInit(): void {
    if (this.startDate != null) {
      this.date.setValue(this.startDate);
    }
  }


  changeDay(inc: number) {
    let day = moment(this.date.value).add(inc, 'days').toDate();
    this.date.setValue(day);
  }

  emitDay() {
    this.onDayChange.emit(moment(this.date.value).format("YYYY-MM-DD"));
  }
}
