import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminRoutingModule } from './admin-routing.module';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin.component';
import { ShareModule } from './share/share.module';
import { WoModule } from '../wo-module/wo.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MatBadgeModule } from "@angular/material/badge";
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { UserImgServices } from './share/services/user-img.services';
import { ReportsModule } from './reports/reports.module';
import { StartComponent } from './start/start.component';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
// import { SettingsComponent } from './settings/settings/settings.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';


export const MY_FORMATS = {
  // useUtc: true,
  parse: {
    dateInput: 'MM/DD/YYYY',
    // useUtc: true,
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
    // useUtc: true,
  },
};

@NgModule({
  imports: [
    AdminRoutingModule,
    CommonModule,
    FormsModule,
    ShareModule,
    WoModule,
    MatExpansionModule,
    MatIconModule,
    MatTooltipModule,
    MatBadgeModule,
    MatCardModule,
    ReportsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatRippleModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatIconModule
  ],
  declarations: [
    AdminComponent,
    LoginComponent,
    ForgotPasswordComponent,
    DashboardComponent,
    PasswordResetComponent,
    StartComponent,
    // SettingsComponent,
  ],
  exports: [
    MatIconModule,
  ],
  providers: [
    UserImgServices
]
})
export class AdminModule { }
