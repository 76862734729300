import { Injectable } from '@angular/core';

import { CanActivate, CanActivateChild, Router } from '@angular/router';

import { ApiGeneralService } from '@app/share/api-general.service';



@Injectable()

export class AuthInstanceGuard implements CanActivateChild {

  constructor(private api: ApiGeneralService, private router: Router) { }

  canActivateChild(): Promise<boolean> {
    return this.api
      .send('auth/can-domain-access')
      .then((res: { must_redirect: boolean; url: string; }) => {
        if (res.must_redirect) {
          // Redirect to the wrong client page if necessary
          document.location.href = `${res.url}/wrong-client`;
          return false;
        }
        return true;
      })
      .catch((err) => {
        console.log(err);
        // Handle API call errors here, e.g., redirect or show an error page
        this.router.navigate(['/error']);
        return false;
      });
  }
}
