<h1 mat-dialog-title>Import reward points</h1>
<div mat-dialog-content style="text-align: center;">  
  <ng-container *ngIf="errors.length > 0; else noErrors">
      <div style="color: red;" *ngFor="let error of errors">
          {{error['message']}}
      </div>
  </ng-container>
  <ng-template #noErrors>
    <mat-spinner diameter="35" *ngIf="loading"></mat-spinner>
    <div style="color: green;" *ngIf="!loading && errors.length == 0">
      Points were granted successfully
    </div>
  </ng-template>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="true">{{button_ok}}</button>
</div>