import { Injectable } from '@angular/core';
@Injectable()
export class ColorService {
    // Array of bright colors
    // private static colorArray = ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
		  // '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
		  // '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
		  // '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
		  // '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
		  // '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
		  // '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
		  // '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
		  // '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
		  // '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];

    private static colorArray = [
        // 500
        '#F44336',
        '#E91E63',
        '#9C27B0',
        '#673AB7',
        '#3F51B5',
        '#2196F3',
        '#03A9F4',
        '#00BCD4',
        '#009688',
        '#4CAF50',
        '#8BC34A',
        '#CDDC39',
        '#FFEB3B',
        '#FFC107',
        '#FF5722',
        '#795548',
        '#9E9E9E',
        '#607D8B',

        // 900
        // '#B71C1C',
        // '#880E4F',
        // '#4A148C',
        // '#311B92',
        // '#1A237E',
        // '#0D47A1',
        // '#01579B',
        // '#006064',
        // '#004D40',
        // '#1B5E20',
        // '#33691E',
        // '#827717',
        // '#F57F17',
        // '#FF6F00',
        // '#E65100',
        // '#BF360C',
        // '#3E2723',
        // '#212121',
        // '#263238',
    ];



    
    // Get color with seed
    public static getRandomColor(seed : string) : string {
        const hash = this.getHashFromString(seed);
        const index = this.getCuttedValue(Math.abs(hash));
        return this.colorArray[index];
    }

    // String to number
    private static getHashFromString(str: string) : number {
        var hash = 0, i, chr;
        if (str.length === 0) return hash;
        for (i = 0; i < str.length; i++) {
          chr   = str.charCodeAt(i);
          hash  = ((hash << 5) - hash) + chr;
          hash |= 0; // Convert to 32bit integer
        }
        return hash;
    };

    // Number to value from 0 to 1
    private static getCuttedValue(x : number) {
        return x % this.colorArray.length;
    }

    public static getTextColor(color: string) : string {
        let rgb = this.hexToRgb(color);
        // http://www.w3.org/TR/AERT#color-contrast
        const brightness = Math.round(((rgb['r'] * 299) +
                              (rgb['g'] * 587) +
                              (rgb['b'] * 114)) / 1000);

        return brightness > 125 ? '#000000' : '#FFFFFF';
    }

    private static hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    }
}
