<div>
    <section class="content-header">
      <h1>{{metaPage.metaTitle}}</h1>
    </section>

    <!-- <div class="content">
        <div class="box" *ngIf="canDownload">
            <div class="box-body">
                <div style="display: flex;"> -->
                    <app-reports></app-reports>
                <!-- </div>
            </div>
        </div>
    </div> -->
</div>