import { Component, Input, OnInit } from '@angular/core';
import { TimeZonesService } from '@app/share/timezones.service';

@Component({
  selector: 'timezoned-clock',
  templateUrl: './timezoned-clock.component.html',
  styleUrls: ['./timezoned-clock.component.scss']
})
export class TimezonedClockComponent implements OnInit {

  @Input()
  timezone : string;

  @Input()
  timezone_name : string;

  @Input()
  loading : boolean;
  

  constructor(
    public timezoneService: TimeZonesService
  ) { }

  ngOnInit(): void {
  }

}
