import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, FormGroup } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class MonthPickerComponent implements OnInit {

  @ViewChild('picker') private rangePicker;
  monthObj = {};

  @Output()
  onMonthChange : EventEmitter<UntypedFormControl> = new EventEmitter();

  @Input()
  startFormControl : UntypedFormControl;
  @Input()
  appearance : String;

  formControl : UntypedFormControl = new UntypedFormControl(moment());

  constructor() { }

  ngOnInit(): void {
    if (this.startFormControl != null) {
      this.formControl.setValue(this.startFormControl.value);
      this.onMonthChange.emit(this.formControl);
    }
  }

  chosenYearHandler(normalizedYear: moment.Moment) {
    const ctrlValue = this.formControl.value;
    ctrlValue.year(normalizedYear.year());
    this.formControl.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: moment.Moment, datepicker: MatDatepicker<moment.Moment>) {
    const ctrlValue = this.formControl.value;
    ctrlValue.month(normalizedMonth.month());
    this.formControl.setValue(ctrlValue);
    datepicker.close();
    this.onMonthChange.emit(this.formControl);
  }

  changeMonth(inc: number) {
    let value = moment(this.formControl.value).add(inc, 'months').toDate();

    this.formControl.setValue(value);

    this.onMonthChange.emit(this.formControl);
  }
}
