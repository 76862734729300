import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiAdminService } from './api-admin.service';
import { ApiGeneralService } from './api-general.service';
import { ApiMemberService } from './api-member.service';
import { CoreModule } from '../core/core.module';
import { ApiMobileService } from './api-mobile.service';
import { TimeZonesService } from './timezones.service';
import { ChatService } from './chat.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DndDirective } from './dnd.directive';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ImportUsersDialogComponent } from './import-users-dialog/import-users-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ApiSchedulingService } from './api-scheduling.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMATS } from '@app/admin/admin.module';
import { ImportWorkdayidDialogComponent } from './import-workdayid-dialog/import-workdayid-dialog.component';
import { ImportLogoDialogComponent } from './import-image-dialog/import-logo-dialog.component';
import { LogoService } from './logo.service';
import { LoadExternalDataDialogComponent } from './load-data-dialog/load-external-data-dialog.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';

@NgModule({
    declarations: [
        DndDirective,
        ImportUsersDialogComponent,
        ImportWorkdayidDialogComponent,
        ImportLogoDialogComponent,
        LoadExternalDataDialogComponent,
    ],
    imports: [
        CommonModule,
        CoreModule,
        MatSnackBarModule,
        MatDialogModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatExpansionModule,
        MatListModule,
    ],
    providers: [
        ApiAdminService,
        ApiGeneralService,
        ApiMemberService,
        ApiMobileService,
        ApiSchedulingService,
        TimeZonesService,
        LogoService,
        ChatService,
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    ],
    exports: [
        DndDirective,
        ImportUsersDialogComponent,
        ImportLogoDialogComponent,
        LoadExternalDataDialogComponent
    ],
})
export class ShareModule { }
