import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { AppStorageService } from '@app/core/app-storage.service';
import * as moment from 'moment';
import { Observable, Subscription, timer } from 'rxjs';

@Component({
  selector: 'analog-watch-panel',
  templateUrl: './analog-watch-panel.component.html',
  styleUrls: ['./analog-watch-panel.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        width: '400px',
        height: '105px',
      })),
      state('closed', style({
        width: '0px',
        height: '30px',
      })),
      transition('* => closed', [
        animate('0.3s')
      ]),
      transition('* => open', [
        animate('0.3s')
      ]),
    ])
  ]
})
export class AnalogWatchPanelComponent implements OnInit {

  @Input()
  timezones: {key: string, title: string}[] = [
    {key: 'America/Los_Angeles', title: 'Los Angeles (PT)',},
    {key: 'America/Denver', title: 'Denver (MT)',},
    {key: 'America/Chicago', title: 'Chicago (CT)',},
    {key: 'America/New_York', title: 'New York (ET)',},
  ];

  currentMoment: moment.Moment = moment();

  currentMomentSource: Observable<number>;
  currentMomentSubscription$: Subscription;

  closed : boolean = false;

  @Input()
  closable : boolean = true;

  @Input()
  bgColor : string = '#eaecfa';

  constructor(
    public localStorage: AppStorageService
  ) {
    this.closed = localStorage.getParam('timezones_closed', false);
  }

  ngOnInit(): void {
    if (this.currentMomentSource == null) {
      this.currentMomentSource = timer(0, 1000);
      this.currentMomentSubscription$ = this.currentMomentSource.subscribe(i => {
        if (!this.closed) {
          this.currentMoment = moment();
        }
      });
    }
  }

  getTimezonedDate(timezone: string): moment.Moment {
    return moment.tz(this.currentMoment, timezone);
  }

  toggleClosed() {
    this.closed = !this.closed;
    this.localStorage.setParam('timezones_closed', this.closed);
  }
}
