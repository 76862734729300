<div class="c-login-form__wrapper">
    <div class="c-login-form">
        <div class="c-login-form__title">
            <app-logo [light]="false"></app-logo>
        </div>

        <div class="c-login-form__title">
            Reset Password
        </div>

        <wo-flash></wo-flash>

        <div class="c-login-form__content">
            <div *ngIf="isLoading" class="slider__wrapper">
                <div class="slider">
                  <div class="line"></div>
                  <div class="break dot1"></div>
                  <div class="break dot2"></div>
                  <div class="break dot3"></div>
                </div>
            </div>

            <div class="c-login-form__note">
                Please enter your email address.
            </div>

            <form action="javascript:;" (submit)="send()">
                <div class="b-form-row">
                    <div class="b-form-row__field">
                        <div class="b-form-row__field-icon">
                            <i class="fa fa-envelope-o" aria-hidden="true"></i>
                            <input class="form-control" [ngClass]="{'error': formErrors?.email }" placeholder="Email" [(ngModel)]="email" type="text" name="email">
                        </div>
                        <div [ngClass]="{show: formErrors?.email}" class="label-error">
                            {{formErrors?.email}}
                        </div>
                    </div>
                </div>
                <div class="form-controls">
                    <a routerLink="/admin/login">Back to login</a>
                    <input [ngClass]="{'btn-loading':isLoading}" type="submit" value="Submit">
                </div>
            </form>
        </div>
    </div>
</div>