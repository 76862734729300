<article class="container">
    <button mat-icon-button (click)="changeWeek(-1)">
        <mat-icon>arrow_back_ios_new</mat-icon>
    </button>
    <mat-form-field [appearance]="appearance">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker" [formGroup]="dateRangeGroup">
            <input readonly matStartDate #start placeholder="Start date" (dateChange)="dateRangeChange(start.value)"
                formControlName="start">
            <input readonly matEndDate #end placeholder="End date" formControlName="end">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <button mat-icon-button (click)="changeWeek(+1)">
        <mat-icon>arrow_forward_ios</mat-icon>
    </button>
</article>