import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'week-picker',
  templateUrl: './week-picker.component.html',
  styleUrls: ['./week-picker.component.scss']
})
export class WeekPickerComponent implements OnInit {

  @ViewChild('start') start: ElementRef;
  @ViewChild('end') end: ElementRef;
  @ViewChild('picker') private rangePicker;
  weekObj = {};

  @Output()
  onWeekChange : EventEmitter<UntypedFormGroup> = new EventEmitter();

  @Input()
  startDateRangeGroup : UntypedFormGroup;
  @Input()
  appearance : String;

  dateRangeGroup = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl()
  });

  constructor() { }

  ngOnInit(): void {
    if (this.startDateRangeGroup != null) {
      this.dateRangeGroup = this.startDateRangeGroup;
      this.onWeekChange.emit(this.dateRangeGroup);
    }
  }


  changeWeek(value: number) {
    let from = moment(this.dateRangeGroup.get('start').value).add(value, 'weeks').toDate();
    let to = moment(this.dateRangeGroup.get('end').value).add(value, 'weeks').toDate();

    this.dateRangeGroup.get('start').setValue(from);
    this.dateRangeGroup.get('end').setValue(to);

    this.onWeekChange.emit(this.dateRangeGroup);
  }

  dateRangeChange(dateRangeStart: any) {
    let currentDate = moment(dateRangeStart);
    let currentEndDate = moment(dateRangeStart);

    let weekStart = currentDate.startOf('isoWeek');
    let weekEnd = currentEndDate.endOf('week');

    let formattedStart = weekStart.format('L');
    let formattedEnd = weekEnd.format('L');

    //add end week to obj

    Object.defineProperty(this.weekObj, 'startweek', {
      get: function () {
        return formattedStart !== 'Invalid date' ? formattedStart : '';
      },
      configurable: true
    });

    Object.defineProperty(this.weekObj, 'endweek', {
      get: function () {
        return formattedEnd !== 'Invalid date' ? formattedEnd : '';
      },
      configurable: true
    });


    this.start.nativeElement.value = weekStart.toDate();

    this.start.nativeElement.value =
      formattedStart !== 'Invalid date' ? formattedStart : '';
    this.end.nativeElement.value =
      formattedEnd !== 'Invalid date' ? formattedEnd : '';

    setTimeout(() => {
      if (dateRangeStart) {
        this.dateRangeGroup.setValue({
          start: weekStart.toDate(),
          end: weekEnd.toDate()
        });
        this.rangePicker.close();
      }
      this.onWeekChange.emit(this.dateRangeGroup);
    }, 0);
  }
}
