<!-- <div class="dummy-card"> -->
    <article class="clock">
        <div class="num num1"><div>1</div></div>
        <div class="num num2"><div>2</div></div>
        <div class="num num3"><div>3</div></div>
        <div class="num num4"><div>4</div></div>
        <div class="num num5"><div>5</div></div>
        <div class="num num6"><div>6</div></div>
        <div class="num num7"><div>7</div></div>
        <div class="num num8"><div>8</div></div>
        <div class="num num9"><div>9</div></div>
        <div class="num num10"><div>10</div></div>
        <div class="num num11"><div>11</div></div>
        <div class="num num12"><div>12</div></div>
        <div class="hr-hand" #hrHand></div>
        <div class="min-hand" #minHand></div>
        <div class="sec-hand" #secHand></div>
    </article>
<!-- </div> -->