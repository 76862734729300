import { Injectable } from '@angular/core';
import { HttpService } from '../core/http.service';
import { environment } from '../../environments/environment';
import { ApiAdminService } from '@app/share/api-admin.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { AuthService } from '@app/auth/auth.service';

@Injectable()
export class ChatService {
  public static _unread: number = 0;
  public meta_topics = {};
  public online = [];
  public static tinode: any;
  public static socket;
  public id = 1;
  public connected: boolean = false;
  public static currentTopic: string = null;
  wsMessages$ = new Subject<any>();
  isConnected = false;
  FCM_token = '';

  get unread() {
    return ChatService._unread;
  }

  public messages = [];
  constructor(
    private api: ApiAdminService,
    private snackBar: MatSnackBar,
    public router: Router,
    public auth: AuthService
  ) { }

  public init(FCM_token) {
    this.connect(FCM_token);
  }

  public connect(FCM_token) {
    if (this.isConnected == false) {
      ChatService.socket = new WebSocket(
        (environment.CHAT_SECURE ? 'wss' : 'ws') +
        '://' +
        environment.CHAT_HOST +
        '/?apiKey=' +
        environment.CHAT_KEY
      );
      ChatService.socket.onopen = () => {
        this.isConnected = true;
        this.login(FCM_token);
        console.log('WS CONNECTED');
      };

      ChatService.socket.onmessage = (e) => {
        let message = JSON.parse(e.data);
        this.wsMessages$.next(message);

        if (message['action'] === 'receive-message') {
          if (
            message['data']['is_read'] == 0 &&
            message['data']['is_own'] == 0
          ) {
            this.api
              .send('chat/get-message', {
                chat_topic_name: message['data']['chat_topic_name'],
                seqid: message['data']['seqid'],
              })
              .then((res) => {
                var audio = new Audio('/assets/audio/msg.mp3');
                audio.play();
                this.snackBar
                  .open(res['message']['content'], res['from']['name'], {
                    verticalPosition: 'bottom',
                    horizontalPosition: 'end',
                    duration: 4 * 1000,
                  })
                  .onAction()
                  .subscribe(() => {
                    this.router.navigate([
                      'admin/chat',
                      res['message']['from_user_id'],
                    ]);
                  });
              });
            this.send('meta', {});
          }
        } else if (message['action'] === 'login') {
          this.send('meta', {});
        } else if (message['action'] === 'meta') {
          ChatService._unread = message['data']['unread'];
          this.meta_topics = message['data']['topics'];
        } else if (message['action'] === 'online') {
          this.online = message['data']['online'];
        } else if (message['action'] === 'toggle-chat') {
          let currentUrl = this.router.url;
          if (currentUrl.startsWith('/admin/chat')) {
            this.router.navigate(['/admin/dashboard'])
          }
        }
      };

      ChatService.socket.onclose = (e) => {
        console.log('close');
        this.isConnected = false;
        setTimeout(() => {
          this.connect(FCM_token);
        }, 1000);
      };

      ChatService.socket.onerror = function (err) {
        console.error(
          'Socket encountered error: ',
          err.message,
          'Closing socket'
        );
        ChatService.socket.close();
      };
    }
  }

  public login(FCM_token = '') {
    this.send('login', {
      accessToken: this.auth.getToken(),
      FCM_token: FCM_token,
      platform: 'Web',
    });
  }

  public send(action: string, data) {
    this.id += 1;

    if (this.isConnected === true) {
      ChatService.socket.send(
        JSON.stringify({
          id: this.id,
          action: action,
          data: data,
        })
      );
    } else {
      let counter = 0;
      let timer = setInterval(() => {
        counter += 1;
        if (counter > 10) {
          clearInterval(timer);
        } else if (this.isConnected === true) {
          ChatService.socket.send(
            JSON.stringify({
              id: this.id,
              action: action,
              data: data,
            })
          );
          clearInterval(timer);
        }
      }, 1000);
    }
  }

  public subscribe(topicUid: string) {
    ChatService.currentTopic = topicUid;
    // this.id += 1;
    this.send('subscribe-topic', { topic_uid: topicUid });
    // ChatService.socket.send(JSON.stringify({
    //     id: this.id,
    //     action: "subscribe-topic",
    //     data: {
    //         topic_uid: topicUid,
    //     },
    // }));
  }

  public sendMessage(msg: string, topicUid = null) {
    this.send('send-message', {
      topic_uid: topicUid || ChatService.currentTopic,
      content: msg,
    });
  }

  public requestMessages(topicUid: string = null, beforeSeqid: number) {
    this.send('message-list', {
      topic_uid: topicUid || ChatService.currentTopic,
      before: beforeSeqid,
    });
  }

  public unsubscribe() {
    this.id += 1;
    ChatService.currentTopic = null;
    ChatService.socket.send(
      JSON.stringify({
        id: this.id,
        action: 'unsubscribe-topic',
        data: {},
      })
    );
  }

  public logout() {
    ChatService.currentTopic = null;
    this.send('logout', {});
  }
}
