import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileUploadService } from '@app/core/file-upload-service';
import { AlertMatDialogComponent } from '@app/modal/alert-mat-dialog/alert-mat-dialog.component';
import { ConfirmMatDialogComponent, MatDialogData } from '@app/modal/confirm-mat-dialog/confirm-mat-dialog.component';
import { environment } from '@env/environment';
import { ApiAdminService } from '../api-admin.service';

interface externalData {
  'users' : Array<any>,
  'groups' : Array<any>,
  'userShifts' : Array<any>,
  'timetables' : Array<any>,
}

@Component({
  selector: 'load-external-data-dialog',
  templateUrl: './load-external-data-dialog.component.html',
  styleUrls: ['./load-external-data-dialog.component.scss']
})
export class LoadExternalDataDialogComponent implements OnInit {
  loading: boolean = false;
  data : externalData | null = null;
  constructor(
    public api: ApiAdminService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AlertMatDialogComponent>,
  ) { }

  ngOnInit(): void {
    this.loadAllData()
  }

  loadAllData() {
    this.loading = true;
    this.api.send('settings/load-external-data', {force: false}).then(res => {
      this.data = res as externalData;
      this.loading = false;
    }).catch(err => this.loading = false);
  }

  uploadAllData() {
    this.dialog.open(ConfirmMatDialogComponent, {data: {
      button_no: "CANCEL",
      button_yes: "CONFIRM",
      description: "AFTER CONFIRM ALL SHIFTS AND SEATS DATA\nWILL BE ERASED AND REWRITED BY NEW DATA, CONFIRM?",
      title: "WARNING"
    } as MatDialogData}).afterClosed().subscribe(res => {
      if (res) {
        this.loading = true;
        this.data = null;
        this.api.send('settings/load-external-data', {force: true}).then(res => {
          this.loading = false;
          this.dialogRef.close(true);
        }).catch(err => {
          this.loading = false
          if (err == true) {
            this.dialogRef.close(true);
          }
        });
      } else {
        this.dialogRef.close(false);
      }
    })
  }
}
