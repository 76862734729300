import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';

@Component({
  selector: 'client-code-panel',
  templateUrl: './client-code-panel.component.html',
  styleUrls: ['./client-code-panel.component.scss']
})
export class ClientCodePanelComponent implements OnInit {

  constructor(
    public authService: AuthService
  ) { }

  ngOnInit(): void {

  }

}
