<article class="container">
    <button mat-icon-button (click)="changeMonth(-1)">
        <mat-icon>arrow_back_ios_new</mat-icon>
    </button>
    <mat-form-field [appearance]="appearance">
        <mat-label>Select Month</mat-label>
        <input readonly matInput [matDatepicker]="dp" placeholder="Month and Year" [formControl]="formControl">
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
            (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker"></mat-datepicker>
    </mat-form-field>
    <button mat-icon-button (click)="changeMonth(+1)">
        <mat-icon>arrow_forward_ios</mat-icon>
    </button>
</article>