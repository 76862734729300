<div class="m-select-icon">
	<div class="m-select-icon__body">
		<div class="m-select-icon__body-left-side">
			<div class="m-select-icon__header">
				<div class="m-select-icon__header-title">
					Choose an icon
				</div>
				<div class="m-select-icon__header-search">
					<form (submit)="doSearch()" action="javascript:void(0);">
						<mat-form-field>
							<mat-label>Search</mat-label>
							<input [(ngModel)]="searchText" name="search" matInput>
							<button mat-icon-button matSuffix>
								<i class="fa fa-search" aria-hidden="true"></i>
							</button>
						</mat-form-field>
					</form>
				</div>
			</div>
			<div class="m-select-icon__body-icons">
				<div class="body-icons__group" *ngFor="let group of groupIcons">
					<div class="body-icons__group-title">
						{{group.name}}
					</div>
					<div class="body-icons__group-icons">
						<div *ngFor="let icon of group.items" (click)="doSelectIcon(icon)" [class.selected]="icon.name === icon_code" class="body-icons__group-icon-item">
							<div class="group-icon-item__img">
								<mat-icon>{{icon.name}}</mat-icon>
							</div>
							<div class="group-icon-item__title">
								{{icon.title}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="m-select-icon__body-righ-side">
			<div class="m-select-icon__header">
				<div class="m-select-icon__header-title">
					Pick a color
				</div>
			</div>
			<div class="m-select-icon__body-color-picker">
				<div style="width:236px; height: 200px; position: relative;">
					<img style="margin-right:2px;" src="/assets/pic/colormap.gif" usemap="#colormap">
					<div
						style="position: relative; width: 21px; height: 21px; background-image: url(/assets/pic/selectedcolor.gif);"
						*ngIf="posX && posY"
						[style.left]="posX"
						[style.top]="posY"
					></div>
					<map id="colormap" name="colormap" style="cursor:pointer">
						<area *ngFor="let area of areas" shape="poly" coords="{{ area.coords }}" (click)="selectArea(area)">
					</map>
				</div>
			</div>
			<div class="m-select-icon__body-icon-preview">
				<div class="body-icon-preview__title">
					Result
				</div>
				<div class="m-select-icon__body-icon-preview-wrapper">
					<mat-icon [style.color]="'#' + hexModel">{{icon_code}}</mat-icon>
				</div>
			</div>
		</div>
	</div>

	<div class="m-select-icon__footer">
		<button mat-flat-button color="primary" (click)="doSelect()">Confirm</button>
		<button type="button" (click)="doCancel()" mat-stroked-button>Cancel</button>
	</div>
</div>
