<div class="container">
  <button mat-icon-button (click)="onClose()" class="dialog-close-btn">
    <mat-icon>close</mat-icon>
  </button>
  <div class="footer_stepper">
    <button mat-button color="primary" (click)="goToPreviousStep()"
      [disabled]="stepper.selectedIndex === 0">Back</button>

    <mat-horizontal-stepper [linear]="isLinear" headerPosition="bottom" #stepper
      (selectionChange)="onStepChange($event)">
      <mat-step [completed]="selectedFileType" state="fileType">
        <ng-template matStepLabel>Choose How To Import</ng-template>
        <div class="radio_grp_cntnr">
          <div class="custom_radio">
            <input type="radio" id="csv" name="fileType" value="csv" [(ngModel)]="selectedFileType"
              (ngModelChange)="onFileTypeSelected($event)">
            <label for="csv">
              <span class="label_wrapper">
                <span class="body">
                  <h2>Import From CSV</h2>
                  <i>Import from CSV file</i>
                </span>
                <mat-icon>insert_drive_file</mat-icon>
                <span class="tick-mark">
                  <mat-icon>done</mat-icon>
                </span>
              </span>
            </label>
          </div>

          <div class="custom_radio">
            <input type="radio" id="excel" name="fileType" value="excel" [(ngModel)]="selectedFileType"
              (ngModelChange)="onFileTypeSelected($event)">
            <label for="excel">
              <span class="label_wrapper">
                <span class="body">
                  <h2>Import From Excel</h2>
                  <i>Import from Excel file <br> ('xls', 'xlsx')</i>
                </span>
                <mat-icon>insert_drive_file</mat-icon>
                <span class="tick-mark">
                  <mat-icon>done</mat-icon>
                </span>
              </span>
            </label>
          </div>
        </div>
      </mat-step>

      <mat-step [completed]="fileUploaded" state="fileUpload">
        <ng-template matStepLabel>Upload Your File</ng-template>
        <!-- <div *ngIf="stepper.selectedIndex === 1"> -->
        <app-upload-file #uploadFileComponent (fileUploaded)="onFileUploaded($event)"
          (disableNext)="disableNextBtn($event)">
        </app-upload-file>
        <!-- </div> -->
      </mat-step>

      <mat-step [completed]="stepper.selectedIndex == 3 && errors.length == 0" state="matchFields">
        <ng-template matStepLabel>Match Fields</ng-template>
        <div *ngIf="stepper.selectedIndex === 2">
          <app-match-fields [columnsToFormat]="columnsToFormat" (errorFieldsChange)="onErrorFieldsChange($event)"
            (mappedFieldsArray)="getMappedFields($event)" (isLoading)="loadingData($event)"
            (disableNext)="disableNextBtn($event)"></app-match-fields>
        </div>
      </mat-step>

      <!-- <mat-step state="completed">
        <ng-template matStepLabel>Import</ng-template>
      </mat-step> -->

      <!-- change default 'edit' icon -->
      <ng-template matStepperIcon="edit">
        <mat-icon>edit</mat-icon>
      </ng-template>

      <!-- Icon overrides. -->
      <ng-template matStepperIcon="fileType">
        <mat-icon>insert_drive_file</mat-icon>
      </ng-template>

      <ng-template matStepperIcon="fileUpload">
        <mat-icon>upload</mat-icon>
      </ng-template>

      <ng-template matStepperIcon="matchFields">
        <mat-icon>compare_arrows</mat-icon>
      </ng-template>
      <ng-template matStepperIcon="completed">
        <mat-icon>done_all</mat-icon>
      </ng-template>

    </mat-horizontal-stepper>

    <ng-container *ngIf="errors.length == 0 && stepper.selectedIndex == 2; else nxtBtn">
      <button mat-flat-button color="primary" (click)="submit()">Import</button>
    </ng-container>
    <ng-template #nxtBtn>
      <button mat-flat-button color="primary" (click)="goToNextStep()"
        [disabled]="isNextButtonDisabled() || loading || disableNext" class="nxtBtnCls">
        <mat-icon [matTooltip]="errorTooltip" matTooltipPosition="above"
          *ngIf="errors.length > 0">not_interested</mat-icon>
        {{btnText}}
      </button>
    </ng-template>
  </div>
</div>