import { Component, HostBinding, OnInit } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { ApiGeneralService } from './share/api-general.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
    public title;

    @HostBinding('class.no-logged') '!isLogged()';

    constructor (
        private auth: AuthService,
        public router: Router,
        private api: ApiGeneralService
    ) {
        this.title = environment.APP_NAME;
    }

    isLogged() {
        return !this.auth.isGuest;
    }

    logOut() {
        this.auth.logout();
        this.router.navigate(['/login']);
    }

    ngOnInit(): void {
        this.api.send('auth/can-domain-access').then((res : {must_redirect : boolean, url : string}) => {
            console.log(res)
            if (res.must_redirect) {
                document.location.href = `${res.url}/wrong-client`;
            }
        });
    }
}
