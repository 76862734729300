<div class="container_grid">
    <ng-container *ngIf="loading; else showData">
        <div class="loading__container">
            <mat-spinner [diameter]="30"></mat-spinner>
        </div>
    </ng-container>
    <ng-template #showData>
        <!-- <div class="grid_col align-self-center"> -->
        <div class="txt_cntnr text-align-center">
            <h2>Match Fields</h2>
            <div>
                <p>
                    Match each column from your file to a field in ImIn,
                    or select to skip the column.
                </p>
                <b>Skipped or unmatched fields aren't included in the import.</b>
            </div>
        </div>
        <!-- </div> -->

        <!-- <div class="grid_col"> -->
        <div class="map_importfields_cntnr">
            <div style="padding: 5px 0;">
                <span><b>{{mappedText}}</b></span>
            </div>

            <div class="table__container">
                <table mat-table [dataSource]="rows">
                    <ng-container matColumnDef="your_field">
                        <th class="shift" mat-header-cell *matHeaderCellDef>Your Field</th>
                        <td class="shift-right" mat-cell *matCellDef="let element">
                            <span [class]="">{{element.label}}
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="alert">
                        <th class="shift" mat-header-cell *matHeaderCellDef>Alert</th>
                        <td class="shift-right" mat-cell *matCellDef="let element">

                            <span *ngIf="element.mappedField == 'skip'">
                                <mat-icon [matTooltip]="missingFieldTooltip" matTooltipPosition="above"
                                    color="warn">warning
                                </mat-icon>
                            </span>

                            <span *ngIf="element.isDuplicate">
                                <mat-icon [matTooltip]="duplicateFieldTooltip" matTooltipPosition="above"
                                    color="warn">not_interested
                                </mat-icon>
                            </span>

                        </td>
                    </ng-container>

                    <ng-container matColumnDef="imin_field">
                        <th class="shift" mat-header-cell *matHeaderCellDef>ImIn Field</th>
                        <td class="shift-right" mat-cell *matCellDef="let element">
                            <app-mat-select-mapping appearence="outline" [data]="iminFields" propToCompare="label"
                                propValue="name" (valChange)="handleMatSelectChange($event, element)"
                                [required]="element.required" [isDuplicate]="element.isDuplicate" [(val)]="element.mappedField" ngDefaultControl>
                            </app-mat-select-mapping>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="field_preview">
                        <th class="shift" mat-header-cell *matHeaderCellDef>Field Preview</th>
                        <td class="shift-right" mat-cell *matCellDef="let element">
                            <span [class]="">
                                {{element.sample}}
                            </span>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
                <div class="table__container" [ngClass]="{'box-loading': loading}">
                    <div class="nothing-found"><small *ngIf="rows.length == 0">Nothing found</small></div>
                </div>
            </div>
        </div>
        <!-- </div> -->
    </ng-template>
</div>