import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule} from '@angular/router';
import { WoFlashService } from './wo-flash/wo-flash.service';
import { WoFlashComponent } from './wo-flash/wo-flash.component';
import { WoBreadcrumbsComponent } from './wo-breadcrumbs/wo-breadcrumbs.component';
import { WoBreadCrumbsService } from './wo-breadcrumbs/wo-breadcrumbs.service';


const WO_COMPONENTS = [
    WoFlashComponent,
    WoBreadcrumbsComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
    ],
    declarations: WO_COMPONENTS,
    exports: WO_COMPONENTS,
    providers: [
        WoFlashService,
        WoBreadCrumbsService
    ]
})

export class WoModule {}
